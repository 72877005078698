<!-- TODO:  Fix mutation of props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="card-body py-1">
        <div>
            <div class="border rounded overflow-hidden" :style="{ background: '#f7f7f7' }">
                <div class="f-11 text-muted pt-1 text-center mb-0">
                    {{
                        data.type === 'whole_word'
                            ? 'Click on words to replace them with blanks.'
                            : 'Highlight letters and click ‘Hide’ to replace.'
                    }}
                </div>
                <div class="p-2 word-tags-wrapper d-flex align-items-start">
                    <div v-show="data.type === 'partial_word'" class="mr-2">
                        <div>
                            <b-button
                                v-if="hasUnderlinedWords"
                                class="btn btn-sm f-11 px-1 py-0 text-white pointer btn-primary"
                                :class="hasUnderlinedWords ? 'bg-primary' : 'bg-secondary'"
                                @click="removeUnderline"
                            >
                                Clear
                            </b-button>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap user-select-none">
                        <div
                            v-for="(word, index) in data.words"
                            :key="index"
                            class="position-relative ml-1"
                            style="z-index: 0; height: fit-content"
                        >
                            <span v-if="data.type === 'whole_word'" class="mr-1">
                                <span>
                                    <input
                                        v-show="editingOn === index"
                                        :ref="'word-editor-' + index"
                                        v-model="wordInput"
                                        v-autowidth="{
                                            minWidth: '20px',
                                            comfortZone: 0,
                                        }"
                                        size="sm"
                                        style="min-width: fit-content"
                                        @focus="wordInput = word.value"
                                        @keydown.enter="onWordChanged($event, index)"
                                        @blur="onWordChanged($event, index)"
                                    />
                                    <span
                                        v-show="editingOn !== index && getWordBody(word).trim().length"
                                        class="tags-input-tag user-select-none pointer w-auto"
                                        :class="{ blank: blankWordBody(word) }"
                                        @click="toggleWordBody(index)"
                                        @dblclick="editWordAt(index)"
                                    >
                                        <span>{{ getWordBody(word) }}</span>
                                    </span>
                                </span>
                                <span v-if="editingOn !== index && endWithSpecialCharacter(word)">
                                    <span
                                        v-show="lastCharacter(word).trim().length"
                                        class="tags-input-tag user-select-none pointer w-auto"
                                        :class="{
                                            blank: blankWordLastCharacter(word),
                                        }"
                                        @click="toggleLastCharacter(index)"
                                        @dblclick="editWordAt(index)"
                                    >
                                        <span>{{ lastCharacter(word) }}</span>
                                    </span>
                                </span>
                            </span>

                            <div v-else-if="word.value && word.value.trim()" class="user-select-none">
                                <span v-for="(letter, letterIdx) in word.value.split('')" :key="letter + letterIdx">
                                    <span
                                        class="f-12 word-letter"
                                        :class="{
                                            'blank-letter': letterInPartials(word, letterIdx),
                                            'unblank-letter': letterInSpecificFilter(index, letterIdx),
                                        }"
                                        @click="toggleWordPartialLetter(index, letterIdx)"
                                    >
                                        {{ letter }}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex" @drop="(e) => e.preventDefault()">
                    <VueEditor
                        :ref="`widget_${index}`"
                        v-model="newWord"
                        class="tags-input-text rounded bg-white"
                        :editor-options="customOptions"
                        :placeholder="data.words.length || newWord.trim().length ? '' : 'Enter your complete sentence.'"
                        @text-change="onChangeWordInput"
                    ></VueEditor>
                </div>
            </div>
            <div class="d-flex mt-2 justify-content-between align-items-top">
                <div>
                    <b-form-checkbox
                        v-model="data.letter_blanks"
                        class="f-14"
                        name="letter_blanks"
                        switch
                        @change="updateData"
                    >
                        Letter Blanks
                    </b-form-checkbox>
                </div>
                <div>
                    <b-form-checkbox
                        id="checkbox-9"
                        v-model="data.include_wordbank"
                        name="checkbox-9"
                        class="f-14"
                        :disabled="
                            data.words.some((item) => item.partials.length) ? false : allWordbanks.length ? false : true
                        "
                    >
                        Include in Word Bank
                    </b-form-checkbox>
                    <div
                        v-if="data.include_wordbank"
                        class="wordbanks-wrapper pl-4 mt-2 d-flex flex-wrap align-items-center"
                    >
                        <span
                            v-for="wordbank in allWordbanks"
                            :key="wordbank.id"
                            class="p-1 mr-1 pointer"
                            :class="wordbank.data.id === data.wordbank_id ? 'selected rounded' : ''"
                        >
                            <p
                                class="p-2 m-0"
                                :style="{
                                    backgroundColor: wordbank.data.color,
                                }"
                                @click="switchWordBank(wordbank.data.id)"
                            />
                        </span>
                        <span class="add-new p-1 d-flex pointer" @click="addNewWordbank()">
                            <b-icon icon="plus"></b-icon>
                        </span>
                    </div>
                </div>
            </div>

            <div class="d-flex mt-2 align-items-center">
                <b-form-radio v-model="data.type" name="type" value="whole_word" class="f-14 mr-3">Whole Word</b-form-radio>

                <b-form-radio v-model="data.type" name="type" value="partial_word" class="f-14">Partial Word</b-form-radio>
            </div>

            <div class="btn-expand text-center">
                <a href="#" size="sm" class="btn btn-circle" @click.prevent="showAdvancedOptions = !showAdvancedOptions">
                    <svg
                        viewBox="0 0 16 16"
                        width="1em"
                        height="1em"
                        focusable="false"
                        role="img"
                        aria-label="three dots"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        class="bi-three-dots b-icon bi"
                    >
                        <g>
                            <path
                                d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                            ></path>
                        </g>
                    </svg>
                </a>
            </div>

            <div v-if="showAdvancedOptions || data.answer_type === 'multiple_choice'" class="mt-2">
                <div class="w-75">
                    <label class="font-weight-bold mb-1 f-11 text-muted my-2">Answer Type</label>
                    <b-form-select
                        v-model="data.answer_type"
                        :options="answerOptions"
                        size="sm"
                        class="mt-0 mb-2"
                    ></b-form-select>
                </div>
                <div v-if="data.answer_type === 'multiple_choice'">
                    <label class="f-11 text-muted d-flex justify-content-between font-weight-bold my-2">
                        <div>Answer Options</div>
                        <div>Correct?</div>
                    </label>
                    <MultiOptions v-model="multipleChoices" class="mb-1" :correct-choice="getRightChoice()"></MultiOptions>
                    <div>
                        <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                            <div class="font-weight-bold">Number of Columns</div>
                            <div class="text-secondary">{{ data.number_of_columns }}</div>
                        </label>
                        <b-form-input
                            v-model="data.number_of_columns"
                            type="range"
                            min="1"
                            max="4"
                            @change="updateData"
                        ></b-form-input>
                    </div>
                </div>
                <fieldset v-if="data.letter_blanks">
                    <label class="font-weight-bold mb-1 f-11 text-muted my-2">Blank Style</label>
                    <div class="mb-3 d-flex f-14 justify-content-between align-items-center">
                        <b-form-radio v-model="data.blank_style" value="underline">Underline</b-form-radio>
                        <b-form-radio v-model="data.blank_style" value="box">
                            <span class="d-flex align-items-center justify-content-center">
                                <span class="mr-2">Box</span>
                                <PremiumMarker
                                    v-if="!hasFeature('formatting', document, document.entity_type)"
                                    name="fill-in-the-blank|box"
                                ></PremiumMarker>
                            </span>
                        </b-form-radio>
                        <b-form-radio v-model="data.blank_style" value="circle">
                            <span class="d-flex align-items-center justify-content-center">
                                <span class="mr-2">Circle</span>
                                <PremiumMarker
                                    v-if="!hasFeature('formatting', document, document.entity_type)"
                                    name="fill-in-the-blank|circle"
                                ></PremiumMarker>
                            </span>
                        </b-form-radio>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapGetters } from 'vuex'
import MultiOptions from '../MultiOptions.vue'
import widthOfText from '../../mixins/widthOfText'
import EditPayWall from '../../mixins/EditPayWall'
import PremiumMarker from '../../widgets/premium-marker.vue'

export default {
    name: 'FillInTheBlank',
    components: {
        VueEditor,
        MultiOptions,
        PremiumMarker,
    },
    mixins: [EditPayWall, widthOfText],
    props: ['data', 'index'],
    data() {
        return {
            canUnhide: false,
            wordInput: '',
            newWord: '',
            ranges: [],
            rangesBackup: [],
            editingOn: -1,
            options: [
                { value: 'none', text: 'None' },
                { value: 'blank', text: 'Blank' },
                { value: 'multiple_choice', text: 'Multiple Choice' },
            ],
            showAdvancedOptions: false,
            customOptions: {
                modules: {
                    toolbar: false,
                },
                formats: ['bold', 'italic', 'underline', 'script'],
            },
            selectStarted: false,
        }
    },
    computed: {
        ...mapGetters({
            allWordbanks: 'document/allWordbanks',
            documentItems: 'document/documentItems',
        }),
        hasSelection() {
            return this.ranges.length ? true : false
        },
        newWordContent: {
            get() {
                return this.newWord.replace(/(<([^>]+)>)/gi, '')
            },
            set(value) {
                this.newWord = value
            },
        },
        answerOptions() {
            return this.data.type === 'partial_word'
                ? this.options.filter((op) => op.value !== 'multiple_choice')
                : this.options
        },
        multipleChoices: {
            get() {
                return this.data.choices
            },
            set(choices) {
                if (choices) {
                    this.$store.dispatch('document/updateMultipleChoices', {
                        at: this.index,
                        choices,
                    })

                    this.$emit('change', this.data)
                }
            },
        },
        hasUnderlinedWords() {
            let underlineWords = this.data.words.filter((word) => word.partials.length)
            return !!underlineWords.length
        },
    },
    watch: {
        'data.answer_type': function () {
            this.$store.dispatch('document/storeDocumentState')
        },
        'data.type': function (newType) {
            if (this.data.type === 'partial_word' && this.data.answer_type === 'multiple_choice') {
                // eslint-disable-next-line vue/no-mutating-props
                this.data.answer_type = 'blank'
            }

            const blankWords = this.data.words?.filter((w) => w.partials.length)

            if (blankWords?.length) {
                if (newType === 'whole_word') {
                    for (let word of blankWords) {
                        if (word.partials && word.partials.length) {
                            const special = this.endWithSpecialCharacter(word)
                            let firstPartial = word.partials[0]
                            if (special) {
                                let newPartials = []
                                const wordLen = word.value.length

                                for (let partial of word.partials) {
                                    if (partial.start === 0 && partial.end >= wordLen - 1) {
                                        newPartials.push({
                                            start: 0,
                                            end: wordLen - 1,
                                        })
                                    } else if (partial.end === wordLen && partial.start <= wordLen - 1) {
                                        newPartials.push({
                                            start: wordLen - 1,
                                            end: wordLen,
                                        })
                                    }
                                }
                                word.partials = newPartials
                            } else if (firstPartial.start !== 0 || firstPartial.end !== word.value.length) {
                                word.partials = []
                            }

                            word.specific_filters = []
                        }
                    }
                }
            }

            this.$store.dispatch('document/storeDocumentState')
        },
        'data.words': {
            handler: function () {
                this.newWord = this.data.words.map((w) => w.value).join(' ')
                this.updateWords()
            },
            deep: true,
        },
        'data.include_wordbank': function (value) {
            this.updateWords()
            if (!value) {
                this.removeFromWordbank()
            }
        },
    },
    mounted() {
        if (this.data.subtitle) {
            this.newWord = this.data.subtitle
                .split(' ')
                .filter((w) => w.trim())
                .join(' ')
            document.getElementById('quill-container').children[0].innerHTML = this.data.subtitle
                .split(' ')
                .filter((w) => w.trim())
                .join(' ')
            let el = this.$refs[`widget_${this.index}`]?.quill.root
            if (el && this.$refs[`widget_${this.index}`]) {
                let range = document.createRange()
                let sel = window.getSelection()
                if (range) {
                    range.setStart(el, 1)
                    range.collapse(true)
                    if (sel) {
                        sel.removeAllRanges()
                        sel.addRange(range)
                    }
                }
            }
            this.$refs[`widget_${this.index}`]?.quill.focus()
            this.updateWords()
        }
    },
    methods: {
        handleBlur() {
            this.rangesBackup = this.ranges
            this.ranges = []
        },
        removeUnderline() {
            this.data.words.map((word) => {
                ;(word.partials = []), (word.specific_filters = [])
            })
        },
        onWordChanged(event, index) {
            this.editingOn = -1
            const words = event.target.value.split(' ').filter((w) => w.trim())
            if (words.length) {
                this.$store.dispatch('document/insertWordsAt', {
                    at: this.index,
                    index,
                    words,
                })
            }
            this.wordInput = ''
        },
        clearSelection() {
            if (window.getSelection) {
                if (window.getSelection().empty) {
                    // Chrome
                    window.getSelection().empty()
                } else if (window.getSelection().removeAllRanges) {
                    // Firefox
                    window.getSelection().removeAllRanges()
                }
            } else if (document.selection) {
                // IE?
                document.selection.empty()
            }
            this.ranges = []
            this.rangesBackup = []
        },
        updatePartialWord() {
            let ranges = this.rangesBackup.length ? this.rangesBackup : this.ranges

            ranges.forEach((range) => {
                this.$store.dispatch('document/updatePartialWord', {
                    ...range,
                    itemAt: this.index,
                })
            })

            this.$nextTick(() => {
                this.updateData()
                this.clearSelection()
            })
        },
        getRightChoice() {
            return {
                answer: this.data.words
                    .filter((w) => w.blank)
                    .map((word) => word.value)
                    .join(', '),
                correct: true,
            }
        },
        addNewWordbank() {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.include_wordbank = true

            this.$store.dispatch('document/addNewWordbank', {
                index: this.index,
                words: this.data.words.filter((w) => w.blank).map((w) => w.value),
                wordsArray: this.getBlankWords(),
            })
        },
        switchWordBank(wordbank_id) {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.include_wordbank = true
            if (this.data.words.length) {
                this.$store.dispatch('document/updateWordbank', {
                    index: this.index,
                    words: this.getBlankWords(),
                    target_id: wordbank_id,
                })
            }
        },
        backspace() {
            if (this.newWordContent.trim().length > 0) {
                this.newWord = this.newWordContent.substring(0, this.newWordContent.length - 1)
                return
            }

            if (this.data.words.length) {
                // eslint-disable-next-line vue/no-mutating-props
                this.data.words.splice(this.data.words.length - 1, 1)
            }
            this.updateData()
        },
        editWordAt(index) {
            this.editingOn = index
            this.$nextTick(() => {
                this.$refs['word-editor-' + index][0].focus()
            })
        },
        toggleWordBody(index) {
            if (index === this.editingOn) return

            const word = this.data.words[index]
            const partial = this.endWithSpecialCharacter(word)
                ? {
                      start: 0,
                      end: word.value.length - 1,
                  }
                : {
                      start: 0,
                      end: word.value.length,
                  }

            this.$store.dispatch('document/toggleWordBlank', {
                at: this.index,
                index,
                partial,
            })
            this.$nextTick(() => {
                this.updateData()
            })
        },
        toggleLastCharacter(index) {
            if (index === this.editingOn) return

            const word = this.data.words[index]
            this.$store.dispatch('document/toggleWordBlank', {
                at: this.index,
                index,
                partial: {
                    start: word.value.length - 1,
                    end: word.value.length,
                },
            })
        },
        updateData() {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.subtitle = this.data.words
                .map((w) => w.value)
                .filter((w) => w)
                .join(' ')
            this.$emit('change', this.data)
        },
        onSelectPartialWord(event, index) {
            this.ranges = [
                {
                    at: index,
                    index: event.target.selectionStart,
                    length: event.target.selectionEnd - event.target.selectionStart,
                },
            ]
        },
        onChangeWordInput() {
            this.$store.dispatch('document/updateWords', {
                content: this.newWordContent.trim(),
                at: this.index,
            })
        },
        replaceStrAt(str, index, replacement) {
            return str.substr(0, index) + replacement + str.substr(index + replacement.length)
        },
        getBlankWords() {
            if (!this.data.words.length) return

            let wordbank_words = []
            this.data.words
                .filter((wordItem) => wordItem.partials.length)
                .forEach((w) => {
                    if (w.partials && w.value) {
                        for (let partial of w.partials) {
                            let partialword = w.value.substring(partial.start, partial.end)
                            partialword = partialword.trim()
                            if (partialword) {
                                wordbank_words.push(partialword)
                            }
                        }
                    } else {
                        wordbank_words.push(w.value)
                    }
                })
            return wordbank_words
        },
        updateWords() {
            if (this.data.include_wordbank && this.data.words.length) {
                const target_id = this.data.wordbank_id
                    ? this.data.wordbank_id
                    : this.allWordbanks.length
                      ? this.allWordbanks[0].data.id
                      : undefined
                this.$store.dispatch('document/updateWordbank', {
                    index: this.index,
                    words: this.getBlankWords(),
                    target_id,
                })
            }
        },
        removeFromWordbank() {
            this.$store.dispatch('document/removeFromWordbank', {
                wordbank_id: this.data.wordbank_id,
                index: this.index,
            })
        },
        letterInPartials(word, letterIndex) {
            if (word?.partials) {
                for (let partial of word?.partials) {
                    if (partial.start <= letterIndex && partial.end > letterIndex) {
                        return true
                    }
                }
            }

            return false
        },
        letterInSpecificFilter(wordAt, letter) {
            return this.data.words[wordAt]?.specific_filters?.includes(letter)
        },
        toggleWordPartialLetter(wordAt, letterIndex) {
            const { partials, specific_filters } = this.data.words[wordAt]
            let letterInPartial = false
            let inSpecificFilter = specific_filters.includes(letterIndex)

            for (let partial of partials ? partials : []) {
                if (partial.start <= letterIndex && partial.end > letterIndex) {
                    letterInPartial = true
                    break
                }
            }

            if (inSpecificFilter) {
                this.$store.dispatch('document/updatePartialWord', {
                    at: wordAt,
                    index: letterIndex,
                    length: 1,
                    itemAt: this.index,
                    exclude: inSpecificFilter,
                })
            } else if (letterInPartial) {
                this.$store.dispatch('document/updateSpecificFilter', {
                    itemAt: this.index,
                    at: wordAt,
                    letterIndex,
                })
            } else {
                this.$store.dispatch('document/updatePartialWord', {
                    at: wordAt,
                    index: letterIndex,
                    length: 1,
                    itemAt: this.index,
                    exclude: false,
                })
            }
        },
        endWithSpecialCharacter(word) {
            let lastChar = this.lastCharacter(word)
            return !/[a-zA-Z0-9]/.test(lastChar)
        },
        lastCharacter(word) {
            return word.value?.slice(word.value.length - 1) || ''
        },
        getWordBody(word) {
            if (!this.endWithSpecialCharacter(word)) return word.value
            else return word.value.slice(0, -1)
        },
        blankWordBody(word) {
            if (this.endWithSpecialCharacter(word)) {
                return word?.partials?.find((p) => p.start === 0 && p.end >= word.value.length - 1) ? true : false
            } else {
                return word?.partials?.find((p) => p.start === 0 && p.end === word.value.length) ? true : false
            }
        },
        blankWordLastCharacter(word) {
            if (!word?.partials) {
                return false
            }
            if (this.endWithSpecialCharacter(word)) {
                return word.partials.find((p) => p.end === word.value.length && p.start <= word.value.length - 1)
                    ? true
                    : false
            }
            return false
        },
    },
}
</script>

<style lang="scss">
@import 'Scss/base.scss';
.word-tags-wrapper {
    min-height: 40px;
    .word-letter {
        padding: 0 1px;
        margin-right: 1px;
        background-color: #d6d60488;
        cursor: pointer;
        width: 0.875rem;
        text-align: center;
        display: inline-block;
        &.blank-letter {
            background-color: #dc354588;
        }
        &.unblank-letter {
            background-color: #4287f588;
        }
    }
}
.editor-toolbar {
    &::after {
        display: none !important;
    }
}
.partial-selection {
    font-size: 0.875rem;
}
</style>
