<template>
    <div>
        <b-form-group class="mb-0">
            <b-form-textarea
                :id="'worksheet-input-' + index"
                rows="1"
                max-rows="6"
                placeholder="Enter your prompt here."
                :value="item.definition"
                @input="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { definition: $event },
                    })
                "
            />
        </b-form-group>

        <b-form-group class="pt-2 mb-0">
            <b-form-checkbox
                :checked="item.show_lines"
                :value="1"
                :unchecked-value="0"
                @change="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { show_lines: $event },
                    })
                "
            >
                Show Lines
            </b-form-checkbox>
        </b-form-group>

        <b-form-group class="mb-0">
            <div class="grid-2">
                <label class="text-sm">{{ item.show_lines ? 'Lines' : 'Space' }}</label>
                <div class="text-sm text-right">
                    {{ item.num_of_lines }}
                </div>
            </div>
            <b-form-input
                type="range"
                min="1"
                max="30"
                :value="item.num_of_lines"
                @input="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { num_of_lines: Number($event) },
                    })
                "
            />
        </b-form-group>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
    name: 'OpenResponseItemForm',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState(['worksheet']),
        ...mapGetters({ worksheetItems: 'document/worksheetItems' }),
        item() {
            return this.worksheetItems[this.index]
        },
    },
})
</script>
