<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <VueEditor
                :ref="`widget_${index}`"
                v-model="subtitleInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Write your prompt here."
                @text-change="updateData"
            ></VueEditor>
        </div>

        <div class="row mt-2 w-100">
            <div class="col d-flex flex-column">
                <label class="font-weight-bold mb-2 f-11 text-muted">Answer Format</label>
                <b-form-select
                    v-model="itemData.answer_type"
                    :options="options"
                    size="sm"
                    class="mt-0 mb-2"
                    @change="updateData"
                ></b-form-select>
            </div>

            <div v-if="data.answer_type === 'lines'" class="col d-flex flex-column">
                <label class="font-weight-bold mb-2 f-11 text-muted">Line Style</label>
                <b-form-select
                    v-model="itemData.line_style"
                    :options="lineStyleOptions"
                    size="sm"
                    class="mt-0 mb-2"
                    @change="updateData"
                ></b-form-select>
            </div>

            <!-- Blank answer settings -->
            <div v-if="data.answer_type === 'blank'" class="col d-flex flex-column">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0">Answer Height</label>
                    <span class="f-10 text-muted text-nowrap">{{ itemData.answer_height }} in</span>
                </div>
                <b-form-input
                    v-model="itemData.answer_height"
                    type="range"
                    min="0"
                    max="8"
                    step="0.01"
                    @input="updateData"
                ></b-form-input>
            </div>

            <!-- Single Answer settings -->
            <div v-if="data.answer_type === 'single'" class="col d-flex flex-column">
                <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold">Line Width</div>
                    <div class="text-secondary">{{ data.line_length }} in</div>
                </label>
                <b-form-input v-model="lineLength" type="range" min="0.2" max="7.2" step="0.1"></b-form-input>
            </div>

            <!-- Answer Key -->
            <div class="col-auto d-flex flex-column">
                <label class="font-weight-bold f-11 text-muted mb-0">Answer Key</label>
                <b-form-checkbox
                    :id="`answer-checkbox-${index}`"
                    v-model="itemData.has_answer"
                    name="options"
                    class="f-14 text-muted ml-1 mr-n2"
                    tabindex="-1"
                    switch
                    @change="updateData"
                ></b-form-checkbox>
            </div>
        </div>

        <!-- Lines normal / handwriting settings-->
        <div v-if="itemData.answer_type === 'lines'" class="row pt-3">
            <div class="col">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0">Lines</label>
                    <span class="f-10 text-muted">
                        {{ itemData.number_of_lines }}
                    </span>
                </div>
                <b-form-input v-model="itemData.number_of_lines" type="range" min="1" max="30"></b-form-input>
            </div>

            <div class="col">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="font-weight-bold f-11 text-muted mb-0" :class="{ 'w-50': data.line_style === 'normal' }">
                        Line Height
                    </label>
                    <span class="f-10 text-muted text-nowrap pl-1">{{ itemData.line_height }} in</span>
                </div>
                <b-form-input v-model="lineHeight" type="range" min="0.2" max="3.2" step="0.01"></b-form-input>
            </div>

            <div
                v-if="data.answer_type === 'lines' && data.line_style === 'normal'"
                class="col d-flex flex-column"
                style="z-index: 0"
            >
                <label class="font-weight-bold mb-1 f-11 text-muted">Line Position</label>
                <b-form-radio
                    v-model="itemData.line_position"
                    name="line_position"
                    value="below"
                    class="text-muted radio-option"
                    @change="updateData"
                >
                    Below
                </b-form-radio>
                <b-form-radio
                    v-model="itemData.line_position"
                    name="line_position"
                    value="after"
                    class="text-muted radio-option"
                    @change="updateData"
                >
                    After
                </b-form-radio>
            </div>
        </div>

        <div v-if="itemData.has_answer" class="pt-2">
            <b-form-input
                v-if="itemData.answer_type === 'lines' && itemData.line_style === 'handwriting'"
                id="handwriting-answer-input"
                :ref="`handwriting_answer_widget_${index}`"
                v-model="handWritingAnswerInput"
                placeholder="Answer Key."
                @change="updateData"
            />

            <VueEditor
                v-else
                :ref="`widget_${index}`"
                v-model="answerInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Answer Key."
                @text-change="updateData"
            ></VueEditor>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import { debounce } from 'lodash'

export default defineComponent({
    name: 'OpenResponse',
    components: {
        VueEditor,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            itemData: {
                ...this.data,
            },
            options: [
                { value: 'single', text: 'Single' },
                { value: 'lines', text: 'Lines' },
                { value: 'blank', text: 'Blank' },
            ],
            lineStyleOptions: [
                { value: 'normal', text: 'Normal' },
                { value: 'handwriting', text: 'Handwriting' },
            ],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
            },
        }
    },
    computed: {
        subtitleInput: {
            get() {
                return this.itemData.subtitle
            },
            set(value) {
                this.itemData.subtitle = value
            },
        },
        answerInput: {
            get() {
                return this.itemData.answer
            },
            set(value) {
                this.itemData.answer = value
            },
        },
        handWritingAnswerInput: {
            get() {
                return this.itemData.handwritingAnswer
            },
            set(value) {
                this.itemData.handwritingAnswer = value
            },
        },
        lineHeight: {
            get() {
                return this.itemData.line_height
            },
            set(value) {
                this.itemData.line_height = value
                this.updateData()
            },
        },
        lineLength: {
            get() {
                return this.itemData.line_length
            },
            set(value) {
                this.itemData.line_length = value
                this.updateData()
            },
        },
    },
    watch: {
        'data.answer_type': {
            deep: true,
            handler(value) {
                if (value === 'blank') {
                    this.itemData.line_position = 'below'
                }
            },
        },
        'itemData.number_of_lines': {
            deep: true,
            handler() {
                this.updateData()
            },
        },
    },
    mounted() {
        this.$refs[`widget_${this.index}`]?.quill.focus()
    },
    methods: {
        updateData: debounce(function () {
            this.$emit('change', this.itemData)
        }, 100),
    },
})
</script>

<style lang="scss" scoped>
.radio-option {
    font-size: 11px;
    line-height: 2;
}
</style>
