<template>
    <div class="card-body pb-1 pt-0">
        <div>
            <Draggable
                v-model="itemData.words"
                v-bind="{ handle: '.wordbank-word-handle', animation: 500 }"
                @change="updateData"
            >
                <div
                    v-for="(wordItem, index) in itemData.words"
                    :key="index"
                    class="d-flex align-items-center mb-2 justify-between"
                >
                    <b-input-group>
                        <b-input-group-prepend v-if="itemData.sort_order === 'manual'">
                            <b-button
                                class="wordbank-word-handle"
                                variant="secondary"
                                size="sm"
                                tabindex="-1"
                                style="cursor: grab"
                            >
                                <b-icon icon="grip-horizontal"></b-icon>
                            </b-button>
                        </b-input-group-prepend>

                        <b-form-input
                            v-if="wordItem.manual"
                            :ref="`word-bank-input-${index}`"
                            placeholder="Word"
                            :value="wordItem.word"
                            size="sm"
                            @input="updateWord(index, $event)"
                            @keyup.enter="handleEnterPress($event, index)"
                        ></b-form-input>

                        <div
                            v-else
                            v-b-tooltip.hover
                            title="Click to edit"
                            class="disabled-word cursor-pointer rounded py-1 px-2 border"
                            @click="goto('goto-id-' + wordItem.itemId, 'goto-container', wordItem)"
                        >
                            {{ wordItem.word }}
                        </div>

                        <b-input-group-append v-if="wordItem.manual">
                            <b-button
                                variant="danger"
                                class="delete-button d-flex align-items-center"
                                size="sm"
                                tabindex="-1"
                                style="z-index: 1"
                                @click="removeWord(index)"
                            >
                                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" height="20" width="20">
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                    />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </Draggable>

            <div v-if="!itemData.words.length" class="text-muted">
                <b-row class="justify-content-center my-2">
                    <b-col class="px-0" cols="1" align-self="center">
                        <!-- #3d8bff -->
                        <div class="swal2-icon swal2-info d-flex">
                            <p class="swal2-icon-content my-auto mx-auto">i</p>
                        </div>
                    </b-col>
                    <b-col cols="10" class="pr-0 pl-2 font-italic f-12 text-left">
                        <p class="mb-0 w-100" style="color: #3d8bff">
                            You can add to this word bank automatically by clicking
                            <b>"Add to word bank"</b>
                            on individual questions
                        </p>
                    </b-col>
                </b-row>
            </div>

            <div v-if="!itemData.words.length" class="text-muted f-11 font-italic">Or, add words manually:</div>
            <div class="d-flex mb-2">
                <b-input ref="add-new-multi-option" v-model="newWord" type="text" size="sm" placeholder="Add Word" />
            </div>
        </div>

        <div class="d-flex">
            <div class="w-50 pr-1">
                <label class="font-weight-bold mb-1 f-11 text-muted mt-2">Sort Words:</label>
                <b-form-select
                    v-model="itemData.sort_order"
                    label="Body Font"
                    :options="options"
                    size="sm"
                    class="mt-0 mb-2"
                    @change="sort($event)"
                ></b-form-select>
            </div>

            <div class="w-50 pl-1">
                <label class="font-weight-bold mb-1 f-11 text-muted">Text Case</label>
                <b-form-select
                    v-model="itemData.text_case"
                    :options="optionsForCase"
                    size="sm"
                    class="mt-0 mb-2"
                    @change="updateData"
                ></b-form-select>
            </div>
        </div>

        <div class="btn-expand text-center">
            <a href="#" size="sm" class="btn btn-circle" @click.prevent="showAdvancedOptions = !showAdvancedOptions">
                <svg
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    focusable="false"
                    role="img"
                    aria-label="three dots"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi-three-dots b-icon bi"
                >
                    <g>
                        <path
                            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        ></path>
                    </g>
                </svg>
            </a>
        </div>

        <div v-if="showAdvancedOptions" class="mt-2">
            <div class="mt-2">
                <p class="mb-0 f-12 font-weight-bold text-center">Word Bank Border Options</p>

                <div>
                    <div class="mt-2">
                        <div class="row">
                            <div class="col-md-9">
                                <label class="font-weight-bold mb-0 f-11 text-muted mt-2">Line Thickness</label>
                                <div class="d-flex f-10 justify-content-between mb-0">
                                    <div>Thin</div>
                                    <div>Thick</div>
                                </div>
                                <b-form-input
                                    v-model="itemData.border_width"
                                    type="range"
                                    min="0"
                                    max="10"
                                    @input="updateData"
                                ></b-form-input>
                            </div>

                            <div class="colorpicker-wrapper col-md-3">
                                <label class="font-weight-bold mb-0 f-11 text-muted mt-2">Line Color</label>
                                <b-form-input
                                    v-model="itemData.border_color"
                                    type="color"
                                    @change="updateData"
                                ></b-form-input>
                            </div>
                        </div>

                        <div>
                            <label class="font-weight-bold mb-0 f-11 text-muted">Line Style</label>
                            <div class="d-flex w-100 row">
                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="line-groove"
                                        v-model="itemData.border_style"
                                        value="groove"
                                        name="word_bank_line_style"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="line-groove" class="f-11 text-muted pointer mb-0">
                                        <div class="groove"></div>
                                        Groove
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="dashed-line"
                                        v-model="itemData.border_style"
                                        name="word_bank_line_style"
                                        value="dashed"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="dashed-line" class="f-11 text-muted pointer mb-0">
                                        <div class="dashed"></div>
                                        Dashed
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="solid-line"
                                        v-model="itemData.border_style"
                                        name="word_bank_line_style"
                                        value="solid"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="solid-line" class="f-11 text-muted pointer mb-0">
                                        <div class="solid"></div>
                                        Solid
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="outset-line"
                                        v-model="itemData.border_style"
                                        name="word_bank_line_style"
                                        value="outset"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="outset-line" class="f-11 text-muted pointer mb-0">
                                        <div class="outset"></div>
                                        Outset
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="dotted-line"
                                        v-model="itemData.border_style"
                                        name="word_bank_line_style"
                                        value="dotted"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="dotted-line" class="f-11 text-muted pointer mb-0">
                                        <div class="dotted"></div>
                                        Dotted
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="double-line"
                                        v-model="itemData.border_style"
                                        value="double"
                                        name="word_bank_line_style"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="double-line" class="f-11 text-muted pointer mb-0">
                                        <div class="double"></div>
                                        Double
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="ridge-line"
                                        v-model="itemData.border_style"
                                        value="ridge"
                                        name="word_bank_line_style"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="ridge-line" class="f-11 text-muted pointer mb-0">
                                        <div class="ridge"></div>
                                        Ridge
                                    </label>
                                </div>

                                <div class="d-flex align-items-center col-md-3">
                                    <b-form-radio
                                        id="inset-line"
                                        v-model="itemData.border_style"
                                        value="inset"
                                        name="word_bank_line_style"
                                        @change="updateData"
                                    ></b-form-radio>
                                    <label for="inset-line" class="f-11 text-muted pointer mb-0">
                                        <div class="inset"></div>
                                        Inset
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import WordItem from '../../store/models/WordItem'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'WordBank',
    components: {
        Draggable,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            options: [
                { value: 'random', text: 'Random' },
                { value: 'a-z', text: 'Alphabetically' },
                { value: 'manual', text: 'Manually' },
            ],
            optionsForCase: [
                { value: 'capitalize', text: 'Title Case' },
                { value: 'uppercase', text: 'UPPERCASE' },
                { value: 'lowercase', text: 'lowercase' },
                { value: 'none', text: 'As entered' },
            ],
            showAdvancedOptions: false,
            customToolbar: [
                [
                    'bold',
                    'italic',
                    'underline',
                    {
                        color: ['#F00', '#0F0', '#00F', '#000', '#ededed'],
                    },
                ],
                [],
                [],
            ],
            itemData: {
                ...this.data,
            },
            sortedWords: [],
            newWord: '',
        }
    },
    computed: {
        ...mapGetters({
            documentItems: 'document/documentItems',
        }),
    },
    watch: {
        newWord(val) {
            if (val.trim()) {
                this.addWord(val.trim())
            }
        },
    },
    methods: {
        updateData() {
            this.$emit(
                'change',
                Object.assign(this.data, {
                    ...this.itemData,
                    words: this.itemData.words,
                }),
            )
        },
        updateWord(index, value) {
            this.itemData.words[index].word = value
        },
        removeWord(index) {
            this.itemData.words.splice(index, 1)
            this.updateData()
        },
        addWord(val = '') {
            this.itemData.words.push(new WordItem(true, val))

            this.$nextTick(() => {
                let index = this.itemData.words.length - 1

                this.$refs[`word-bank-input-${index}`][0].$el.focus()

                this.newWord = ''
            })

            this.updateData()
        },
        sort(value) {
            if (value == 'random') {
                this.itemData.words.sort(() => 0.5 - Math.random())
            }

            if (value == 'a-z') {
                this.itemData.words.sort((wi1, wi2) => (!wi1.word ? 1 : !wi2.word ? -1 : wi1.word.localeCompare(wi2.word)))
            }

            this.updateData()
        },
        goto(refName, containerRef, wordItem) {
            if (wordItem.manual) {
                return
            }
            const item = this.documentItems.find((d) => d.id === wordItem.itemId)
            if (!item) {
                return
            }

            this.$emit('goto', { ref: refName, container: containerRef })

            this.$nextTick(() => {
                this.$store.dispatch('document/setWidgetStatus', {
                    openHeader: false,
                    focusedItem: item,
                })
            })
        },
        checkIndex(index) {
            if (typeof this.$refs[`word-bank-input-${index + 1}`] !== 'undefined') {
                return this.$refs[`word-bank-input-${index + 1}`].length > 0
            } else {
                return false
            }
        },
        handleEnterPress(e, index) {
            e.preventDefault()
            if (index === this.itemData.words.length - 1) {
                this.$refs['add-new-multi-option'].focus()
                return
            }
            this.checkIndex(index)
            let flag = false
            while (index <= this.itemData.words.length - 1 && !flag) {
                flag = this.checkIndex(index)
                if (!flag) {
                    index++
                }
            }
            if (index === this.itemData.words.length - 1) {
                this.$refs['add-new-multi-option'].focus()
            }

            if (this.$refs[`word-bank-input-${index + 1}`]) {
                this.$refs[`word-bank-input-${index + 1}`][0].focus()
            } else {
                this.$refs['add-new-multi-option'].focus()
            }
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.disabled-word {
    display: flex;
    flex: 1;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-color: rgb(206, 212, 218);
    background-color: rgb(233, 236, 239);
    border-width: 1px;
    padding: 0 12px;
    align-items: center;
}
</style>

<style scoped>
.swal2-icon {
    width: 23px !important;
    height: 23px !important;
    margin: 0.2em auto 0.6em !important;
}

.swal2-icon-content {
    line-height: 0em !important;
    font-size: 1rem !important;
    font-weight: 600;
}

.swal2-icon .swal2-icon-content {
    height: 80%;
}

.swal2-icon.swal2-info {
    color: #3d8bff !important;
    border: 3px solid #3d8bff !important;
}
</style>
