<template>
    <div :id="`add-question-${itemIndex}`" class="my-2 mx-4 add-item" style="z-index: 15">
        <div
            ref="addItemDropdown"
            class="d-flex rounded p-1 border-none align-items-center widget-drawer"
            style="background-color: #f5f5f5; border: 1px solid #bdbdbd; box-sizing: border-box; border-radius: 4px"
        >
            <div class="mr-1">
                <button v-b-tooltip.hover class="btn btn-sm btn-success" title="Shortcut: Ctrl + Enter" @click="btnAddItem">
                    + Add
                </button>
            </div>
            <div class="flex-grow-1">
                <Multiselect
                    ref="select"
                    v-model="selectedItem"
                    :options="options"
                    :searchable="false"
                    :show-labels="false"
                    group-values="items"
                    group-label="group"
                    @input="addItem"
                    @open="dropdownOpened(true)"
                    @close="dropdownOpened(false)"
                >
                    <template slot="option" slot-scope="{ option }">
                        <div v-if="option.name" class="f-13 option d-flex justify-content-between">
                            <div v-if="option.key">
                                <component :is="option.key"></component>
                            </div>
                            <div>
                                {{ option.name }}
                            </div>
                        </div>
                        <div v-else class="text-body font-weight-bold">
                            {{ option.$groupLabel }}
                        </div>
                    </template>

                    <template slot="singleLabel" slot-scope="{ option }">
                        <div v-if="option.name">
                            {{ option.name }}
                        </div>
                    </template>
                </Multiselect>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { element_types } from '../../objects/Document'
import addItemMap from '../../helpers/AddItemMap'
import matching from '../../../img/icons/matching.vue'
import handwriting from '../../../img/icons/handwriting.vue'
import instruction from '../../../img/icons/instruction.vue'
import basic_text from '../../../img/icons/basic_text.vue'
import blank_space from '../../../img/icons/blank_space.vue'
import word_bank from '../../../img/icons/word_bank.vue'
import word_scramble from '../../../img/icons/word_scramble.vue'
import divider from '../../../img/icons/divider.vue'
import fill_in_the_blank from '../../../img/icons/fill_in_the_blank.vue'
import page_break from '../../../img/icons/page_break.vue'
import open_response from '../../../img/icons/open_response.vue'
import multiple_choice from '../../../img/icons/multiple_choice.vue'
import section_header from '../../../img/icons/section_header.vue'
import checklist from '../../../img/icons/checklist.vue'
import { EventBus } from '../../common/EventBus'
import { mixin as clickaway } from 'vue-clickaway'
import Multiselect from 'vue-multiselect'

export default {
    name: 'AddItem',
    components: {
        matching,
        handwriting,
        instruction,
        word_bank,
        word_scramble,
        divider,
        fill_in_the_blank,
        page_break,
        blank_space,
        open_response,
        basic_text,
        multiple_choice,
        section_header,
        Multiselect,
        checklist,
    },
    mixins: [clickaway],
    props: {
        itemIndex: {
            type: Number,
            default: 0,
        },
        item: {
            type: Object,
            default: () => {},
        },
        openCloseMainMenu: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            options: [],
            activities: [],
            selectedItem: null,
            popOverAtParent: false,
            resetTopInterval: null,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            documentTypes: 'document/allActivities',
            documentItems: 'document/documentItems',
            currentWidget: 'document/currentWidget',
            selected: 'document/selected',
            persistAction: 'document/persistAction',
            defaultSelectedWidget: 'abtests/defaultSelectedWidget',
            isWorksheet: 'document/isWorksheet',
        }),
        defaultWidget() {
            return this.defaultSelectedWidget ?? 'instruction'
        },
        allOptions() {
            return [...this.options[0].items, ...this.options[1].items]
        },
    },
    watch: {
        defaultWidget: {
            handler() {
                this.setDefaultWidget()
            },
        },
        currentWidget: {
            handler(val) {
                if (!this.isWorksheet) {
                    return
                }

                //If a non-header item is active
                if (val.focusedItem) {
                    this.selectedItem = {
                        name: this.allOptions.find((option) => option.key === addItemMap[val.focusedItem.type])?.name,
                        key: addItemMap[val.focusedItem.type],
                    }
                }

                //If the header item is active
                if (val.openHeader) {
                    this.selectedItem = {
                        name: this.allOptions.find((option) => option.key === this.defaultWidget).name,
                        key: this.defaultWidget,
                    }
                }

                //If all items including header are closed.
                if (!val.focusedItem && !val.openHeader) {
                    this.selectedItem = !this.item
                        ? {
                              name: this.allOptions.find((option) => option.key === this.defaultWidget).name,
                              key: this.defaultWidget,
                          }
                        : {
                              name: this.allOptions.find((option) => option.key === addItemMap[this.item.type]).name,
                              key: addItemMap[this.item.type],
                          }
                }
            },
            deep: true,
        },
        openCloseMainMenu: {
            handler(val) {
                if (val === true) {
                    this.$refs.select.open = false
                }
            },
        },
    },
    created() {
        this.instantiateSelector()
        this.setDefaultWidget()

        EventBus.$on('keyboard-event-add-item', this.handleAddItemEvent)
    },
    methods: {
        setDefaultWidget() {
            if (!this.documentItems.length || !this.currentWidget.focusedItem) {
                this.selectedItem = {
                    name: this.allOptions.find((option) => option.key === this.defaultWidget).name,
                    key: this.defaultWidget,
                }
            } else {
                if (this.item?.type) {
                    this.selectedItem = {
                        name: this.allOptions.find((option) => option.key === addItemMap[this.item.type]).name,
                        key: addItemMap[this.item.type],
                    }
                }
            }
        },
        close() {
            this.$refs.select.open = false
        },
        btnAddItem() {
            setTimeout(() => {
                this.addItem()
            }, 20)
        },
        addItem() {
            this.$store.dispatch('document/setSelected', {
                selected: this.selectedItem.key,
            })

            let index = this.itemIndex === undefined ? 0 : this.itemIndex + 1

            this.$store.dispatch('document/pushNewItemWithTypeAt', {
                index,
            })

            this.$nextTick(() => {
                if (this.isLoggedIn) {
                    this.$store.dispatch(this.persistAction)
                }
                this.$store.dispatch('document/scaleDocument', false)
                this.$emit('added', { id: this.documentItems[index]?.id })
            })

            this.goto(`preview-${this.documentItems[index]?.id}`)
        },
        goto(element) {
            this.$forceNextTick(() => {
                const elem = document.getElementById(element)

                if (!elem) return

                if (this.isInViewport(elem)) return

                let anchorSelector = this.getAnchor(element)

                const anchor = document.getElementById(anchorSelector)
                if (anchor) {
                    anchor.scrollIntoView({
                        behavior: 'smooth',
                    })

                    return
                }
            }, 1000)
        },
        isInViewport(element) {
            const rect = element.getBoundingClientRect()
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            )
        },
        getAnchor(ref) {
            if (ref === 'docHeader') {
                return 'anchor-docHeader'
            }

            if (ref === 'instructions' || ref === 'bingo_words') {
                return `anchor-${ref}-0`
            }

            if (ref === 'bingo_call_list') {
                return `anchor-${ref}`
            }

            return `anchor-${ref.substr(8)}`
        },
        handleAddItemEvent() {
            if (this.currentWidget.focusedItem === this.item || this.currentWidget.openHeader) {
                this.addItem()
            }
        },
        instantiateSelector() {
            this.documentTypes.forEach((element) => {
                this.activities.push({
                    key: element?.key,
                    name: element?.name,
                })
            })

            this.options.push(
                {
                    group: 'Activities',
                    items: [],
                },
                {
                    group: 'Elements',
                    items: [],
                },
            )

            this.activities.forEach((activity) => {
                this.options[0].items.push(activity)
            })

            element_types.forEach((element) => {
                this.options[1].items.push(element)
            })
        },
        dropdownOpened(val) {
            this.$emit('addItemDropdownOpened', val)
            let parent = window.document.getElementById('goto-container')
            let child = window.document.getElementById('add-question-' + this.itemIndex)

            let multiselector = this.checkForMultiselectElem(child)
            if (val) {
                if (this.resetTopInterval) clearTimeout(this.resetTopInterval)
                this.$nextTick(() => {
                    //If opened, check if it overflows to the top
                    if (multiselector) {
                        const parentRectangle = parent.getBoundingClientRect()
                        const selectorRectangle = multiselector.getBoundingClientRect()
                        if (selectorRectangle.top < parentRectangle.top) {
                            multiselector.style.top = multiselector.offsetTop + parentRectangle.top + 'px'
                        }
                    }
                })
            } else {
                this.resetTopInterval = setTimeout(() => {
                    if (multiselector) multiselector.style.top = 'unset'
                }, 200)
            }
        },
        checkForMultiselectElem(parentElement) {
            let className = 'multiselect__content-wrapper'

            if (parentElement.classList.contains(className)) {
                return parentElement
            }

            // Recursive case: Search through each child element
            for (let i = 0; i < parentElement.children.length; i++) {
                const nestedDiv = this.checkForMultiselectElem(parentElement.children[i], className)

                // If a nested element with the class is found, return it
                if (nestedDiv) {
                    return nestedDiv
                }
            }

            // If no element with the class is found in any child, return null
            return null
        },
    },
}
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'vue-multiselect/dist/vue-multiselect.min.css';
@import 'Scss/runtime.scss';

.add-item {
    .multiselect {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        max-height: 30px;
        min-height: initial;

        .multiselect__select {
            position: relative;
            min-height: initial;
            width: 16px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:before {
                top: 0;
                color: #000;
                margin-top: 0px;
                border-color: #000 transparent transparent;
            }
        }

        .multiselect__tags {
            min-height: initial;
            flex-grow: 1;
            padding: 0;
            border: none;
            display: flex;
            align-items: center;

            .multiselect__single {
                height: 100%;
                min-height: initial;
                display: block;
                overflow: hidden;
                margin-bottom: 0px;
                background: #f5f5f5;
            }
        }
    }
}

.multiselect__option--highlight {
    background-color: var(--primary, #715dd6);
}

.multiselect__option--selected.multiselect__option--highlight {
    background-color: var(--primary, #715dd6);
}

.multiselect__content-wrapper {
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}

.f-14 {
    font-size: 14px;
}
</style>
