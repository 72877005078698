<template>
    <div>
        <b-form-group class="mb-0 py-2">
            <div class="ml-1 mb-2">
                <b-form-checkbox v-model="question" name="check-button" switch>
                    <span class="text-muted f-13">Use question in Call List</span>
                </b-form-checkbox>
            </div>

            <b-form-input
                :id="'worksheet-input-' + index"
                type="text"
                placeholder="Enter your word"
                class="bingo-input"
                :value="item.term"
                @input="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { term: $event },
                    })
                "
            />

            <b-form-input
                v-if="question || item.definition"
                :id="'bingo-input-' + index"
                type="text"
                class="mt-3 bingo-input"
                placeholder="Enter question for Call List"
                :value="item.definition"
                @input="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { definition: $event },
                    })
                "
            />
        </b-form-group>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
    name: 'BingoItemForm',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            question: false,
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            bingoItems: 'document/bingoItems',
        }),
        item() {
            return this.bingoItems[this.index]
        },
    },
})
</script>
