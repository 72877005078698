<template>
    <div class="puzzle-form-section position-relative" style="user-select: none">
        <div v-if="!widgetEnabled" class="widget-overlay" @click="(e) => e.preventDefault()"></div>
        <div
            v-if="addItemDropDownOpen"
            class="widget-overlay add-item-overlay"
            :style="'opacity: 0.4'"
            @click="(e) => e.preventDefault()"
        ></div>
        <div class="fixed-body-style d-flex align-items-center justify-content-between p-2" style="z-index: 102">
            <div class="d-flex justify-content-end">
                <b-button
                    v-if="documentItems.length || images.length"
                    v-b-tooltip.hover
                    class="btn btn-sm rounded d-flex align-items-center shadow-sm text-white"
                    variant="danger"
                    :style="{
                        'text-danger': isWorksheet,
                    }"
                    :title="isWorksheet ? 'Clear All' : 'Start Over'"
                    @click="clearWidgets"
                >
                    <svg
                        v-if="isWorksheet"
                        height="20px"
                        width="20px"
                        class="text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                    </svg>
                    <b-icon-arrow-clockwise v-else />
                </b-button>
            </div>

            <div class="d-flex align-items-center image-uploader">
                <b-form-checkbox v-if="answerable" v-model="answer_key" switch class="text-white pr-2" :value="1">
                    Answer Key
                </b-form-checkbox>

                <ImageUploader class="ml-2" @visible-changed="(e) => (widgetEnabled = !e)" />

                <BodyStyleSettings
                    v-if="isWorksheet"
                    class="ml-2"
                    @change="onChange('index', $event)"
                    @visible-changed="(e) => (widgetEnabled = !e)"
                />
            </div>
        </div>

        <div class="d-flex flex-grow-1 overflow-hidden position-relative">
            <div
                id="goto-container"
                ref="container"
                class="puzzle-form-container position-relative px-2 pb-2 mb-2"
                :class="{ 'overflow-hidden add-menu-open': addItemDropDownOpen }"
            >
                <!-- Bingo card setup -->
                <ItemFrame
                    v-if="isBingo"
                    title="Card Setup"
                    :opened="currentWidget.bingoCardSetup"
                    goto-id="bingo-card-setup"
                    :add-disabled="true"
                    :editable="true"
                    :hide-options="true"
                    :draggable="false"
                    @goto="goto($event, null)"
                    @on-edit="
                        $nextTick(() => {
                            $store.dispatch('document/setWidgetStatus', {
                                bingoCardSetup: !currentWidget.bingoCardSetup,
                                focusedItem: undefined,
                            })
                        })
                    "
                >
                    <BingoCardSetup :data="{}" @change="onChange('index', $event)" />
                </ItemFrame>

                <!-- Persistent Worsheet header -->
                <ItemFrame
                    :title="headerTitle"
                    :opened="currentWidget.openHeader"
                    goto-id="docHeader"
                    :editable="true"
                    :hide-options="true"
                    :draggable="false"
                    :add-disabled="!isWorksheet"
                    @goto="goto($event, null)"
                    @on-edit="
                        $nextTick(() => {
                            $store.dispatch('document/setWidgetStatus', {
                                openHeader: !currentWidget.openHeader,
                                focusedItem: undefined,
                            })
                        })
                    "
                    @addItemDropdownOpened="addItemDropdownOpened"
                >
                    <WorksheetHeader @change="onChange('index', $event)" />
                </ItemFrame>

                <!-- Activity Forms -->
                <Draggable
                    v-if="isWorksheet"
                    v-model="documentItems"
                    ghost-class="ghost-card"
                    drag-class="draging-card"
                    :animation="500"
                    :empty-insert-threshold="100"
                    handle=".handle-widget"
                    :scroll-sensitivity="200"
                    :force-fallback="true"
                    @start="dragStarted"
                    @end="dragEnded"
                    @change="$store.dispatch('document/renumberItems')"
                >
                    <ItemFrame
                        v-for="(item, index) in documentItems"
                        :key="'item-' + index"
                        :title="getItemTitle(item.type)"
                        :index="index"
                        :goto-id="'preview-' + item.id"
                        :editable="true"
                        :removeable="true"
                        :opened="currentWidget.focusedItem && currentWidget.focusedItem.id === item.id && !closeWidget"
                        :draggable="true"
                        :item="item"
                        :duplicateable="true"
                        @goto="goto($event, item)"
                        @on-edit="
                            $nextTick(() => {
                                $store.dispatch('document/setWidgetStatus', {
                                    openHeader: false,
                                    focusedItem: currentWidget.focusedItem === item ? undefined : item,
                                })
                            })
                        "
                        @on-duplicate="duplicateItem(index)"
                        @on-copy="copyItem(index)"
                        @on-remove="removeItem(index)"
                        @addItemDropdownOpened="addItemDropdownOpened"
                    >
                        <component
                            :is="inputType(item.type)"
                            v-if="item.type"
                            :index="index"
                            :data="item.data"
                            :clicked-index="currentWidget.focusedItem?.clickedIndex"
                            :item-style.sync="item.style"
                            @change="onChange(index, $event)"
                            @goto="goto"
                        />
                    </ItemFrame>
                </Draggable>

                <!-- Bingo and Flashcard stuff -->
                <div v-if="!isWorksheet">
                    <ItemFrame
                        title="Instructions"
                        :opened="currentWidget.openInstructions"
                        goto-id="instructions"
                        :add-disabled="true"
                        :editable="true"
                        :hide-options="true"
                        :draggable="false"
                        @goto="goto($event, null)"
                        @on-edit="
                            $nextTick(() => {
                                $store.dispatch('document/setWidgetStatus', {
                                    openInstructions: !currentWidget.openInstructions,
                                    focusedItem: undefined,
                                })
                            })
                        "
                    >
                        <StandardInstruction :data="instructionsData" @change="setInstructionsData" />
                    </ItemFrame>

                    <div v-if="isBingo">
                        <ItemFrame
                            title="Bingo Words"
                            :opened="currentWidget.openBingoWords"
                            goto-id="bingo_words"
                            :editable="true"
                            :add-disabled="true"
                            :hide-options="true"
                            :draggable="false"
                            @goto="goto($event, null)"
                            @on-edit="
                                $nextTick(() => {
                                    $store.dispatch('document/setWidgetStatus', {
                                        openBingoWords: !currentWidget.openBingoWords,
                                        wordsDefinition: !currentWidget.wordsDefinition,
                                        focusedItem: undefined,
                                    })
                                })
                            "
                        >
                            <BingoWords :data="{}" @change="onChange" />
                        </ItemFrame>

                        <ItemFrame
                            title="Call List"
                            :opened="currentWidget.bingoCallList"
                            goto-id="bingo_call_list"
                            :add-disabled="true"
                            :editable="true"
                            :hide-options="true"
                            :draggable="false"
                            @goto="goto($event, null)"
                            @on-edit="
                                $nextTick(() => {
                                    $store.dispatch('document/setWidgetStatus', {
                                        bingoCallList: !currentWidget.bingoCallList,
                                        pageSettings: !currentWidget.pageSettings,
                                        focusedItem: undefined,
                                    })
                                })
                            "
                        >
                            <BingoCallList :data="{}" @change="onChange" />
                        </ItemFrame>
                    </div>

                    <!-- Flashcards -->
                    <div v-else>
                        <ItemFrame
                            title="Words & Definitions"
                            :opened="currentWidget.wordsDefinition"
                            goto-id="words_definitions"
                            :editable="true"
                            :add-disabled="true"
                            :hide-options="true"
                            :draggable="false"
                            @goto="goto($event, null)"
                            @on-edit="
                                $nextTick(() => {
                                    $store.dispatch('document/setWidgetStatus', {
                                        wordsDefinition: !currentWidget.wordsDefinition,
                                        focusedItem: undefined,
                                    })
                                })
                            "
                        >
                            <WordsDefinition :data="{}" />
                        </ItemFrame>

                        <ItemFrame
                            title="Page Setup"
                            :opened="currentWidget.pageSettings"
                            goto-id="page_setup"
                            :add-disabled="true"
                            :editable="true"
                            :hide-options="true"
                            :draggable="false"
                            @goto="goto($event, null)"
                            @on-edit="
                                $nextTick(() => {
                                    $store.dispatch('document/setWidgetStatus', {
                                        pageSettings: !currentWidget.pageSettings,
                                        focusedItem: undefined,
                                    })
                                })
                            "
                        >
                            <PageSetup :data="pageSetupData" @change="setPageSetupData" />
                        </ItemFrame>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom-action-panel p-2 text-center">
            <!-- Occupies the space while loading ab tests-->
            <button
                v-if="loadingAbAction"
                class="btn btn-clear rounded-pill px-4"
                style="transform: translateX(-8px); opacity: 0"
            >
                {{ makePrintableText }}
            </button>
            <button
                v-else
                class="btn btn-success rounded-pill px-4"
                style="transform: translateX(-8px)"
                @click="showPrintableModal"
            >
                {{ makePrintableText }}
            </button>

            <PrintableModal />
            <OnboardingModal />
            <OnboardingComplete />
            <OnboardingSinglePurchase />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import { mapGetters, mapState } from 'vuex'
import ImageUploader from './ImageUploader.vue'
import BodyStyleSettings from './BodyStyleSettings.vue'
import WorksheetHeader from './WorksheetHeader.vue'
import Instruction from './InstructionBox.vue'
import BlankSpace from './BlankSpace.vue'
import BasicText from './BasicText.vue'
import StandardInstruction from './StandardInstruction.vue'
import WordsDefinition from './WordsDefinition.vue'
import PageSetup from './PageSetup.vue'
import OpenResponse from './OpenResponse.vue'
import MultipleChoice from './MultipleChoice.vue'
import Checklist from './CheckList.vue'
import Handwriting from './Handwriting.vue'
import WordScramble from './WordScramble.vue'
import Matching from './Matching.vue'
import Divider from './Divider.vue'
import PageBreak from './PageBreak.vue'
import WordBank from './WordBank.vue'
import SectionHeader from './SectionHeader.vue'
import FillInTheBlank from './FillInTheBlank.vue'
import BingoCardSetup from './BingoCardSetup.vue'
import BingoWords from './BingoWords.vue'
import BingoCallList from './BingoCallList.vue'

import ItemFrame from '../../widgets/item-manager/item-frame.vue'
import AddQuestion from '../../widgets/item-manager/add-question.vue'
import charmap from '../../widgets/charmap.vue'

import { bingo_types, element_types, flashcard_types, worksheet_activities_types } from '../../objects/Document'
import Errors from '../../libraries/errors'
import { EventBus } from '../../common/EventBus'

import PrintableModal from '../PrintableModal.vue'
import OnboardingModal from '../OnboardingModal.vue'
import OnboardingComplete from '../OnboardingComplete.vue'
import OnboardingSinglePurchase from '../OnboardingSinglePurchase.vue'

import Activity from '../../store/models/Activity'
import Common from '../../mixins/Common'
import StatsigHelper from '../../mixins/StatsigHelper'
import wmAlert from '../../helpers/wmAlerts'

export default defineComponent({
    name: 'SideBar',
    components: {
        BingoWords,
        BingoCardSetup,
        BingoCallList,
        PrintableModal,
        OnboardingModal,
        OnboardingComplete,
        OnboardingSinglePurchase,
        Draggable,
        ImageUploader,
        BodyStyleSettings,
        WorksheetHeader,
        BasicText,
        Instruction,
        OpenResponse,
        MultipleChoice,
        Checklist,
        Handwriting,
        WordScramble,
        Matching,
        Divider,
        BlankSpace,
        PageBreak,
        WordBank,
        SectionHeader,
        ItemFrame,
        AddQuestion,
        FillInTheBlank,
        StandardInstruction,
        WordsDefinition,
        PageSetup,
        charmap,
    },
    mixins: [Common, StatsigHelper],
    data() {
        return {
            showActivities: false,
            openHeader: true,
            focusedItem: undefined,
            errors: new Errors(),
            dragOn: -1,
            widgetEnabled: true,
            closeWidget: false,
            addItemDropDownOpen: false,
        }
    },
    computed: {
        ...mapState(['document', 'user', 'subscription', 'cards']),
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isAdmin: 'user/isAdmin',
            style: 'document/documentStyle',
            documentType: 'document/documentType',
            documentTypes: 'document/allDocumentTypes',
            isDcoumentPublishable: 'document/isPublishable',
            getPremiumFeatures: 'document/getPremiumFeatures',
            documentModel: 'document/documentModel',
            items: 'document/documentItems',
            currentWidget: 'document/currentWidget',
            persistAction: 'document/persistAction',
            isWorksheet: 'document/isWorksheet',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            answerable: 'document/answerable',
            flashcard: 'document/flashcard',
            onboarding: 'document/onboarding',
            neverHadTrial: 'user/neverHadTrial',
            onboardingComplete: 'user/onboardingComplete',
            entityType: 'document/entityType',
            worksheetPrintActionText: 'abtests/worksheetPrintActionText',
            flashcardPrintActionText: 'abtests/flashcardPrintActionText',
            bingoPrintActionText: 'abtests/bingoPrintActionText',
            loadingAbAction: 'abtests/loadingAbAction',
            worksheetTitle: 'abtests/worksheetTitle',
            bingoTitle: 'abtests/bingoTitle',
            flashcardTitle: 'abtests/flashcardTitle',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            showAnswerKey: 'abtests/showAnswerKey',
            getWordBank: 'document/getSortedWordBank',
        }),
        images() {
            if (this.entityType === 'worksheet') return this.worksheetImages
            if (this.entityType === 'flashcard') return this.flashcardImages
            if (this.entityType === 'bingo') return this.bingoImages
            return []
        },
        showOnboardingModal() {
            if (
                !this.isLoggedIn ||
                this.isAdmin ||
                this.user.user.has_plan ||
                this.user.user.subscription_plan != 'Free' ||
                this.onboardingComplete
            ) {
                return false
            }

            return !this.user.user.trialed && !this.cards.cards.length
        },
        instructionsData() {
            return this.document[this.documentType].instruction
        },
        pageSetupData() {
            return {
                flashcard_item_height: this.flashcard.flashcard_item_height,
                page_setup: this.flashcard.page_setup,
            }
        },
        headerTitle() {
            return this.isWorksheet ? 'Worksheet Header' : 'Header'
        },
        makePrintableText() {
            let text = 'Make Printable'
            if (this.entityType === 'worksheet') text = this.worksheetPrintActionText
            if (this.entityType === 'flashcard') text = this.flashcardPrintActionText
            if (this.entityType === 'bingo') text = this.bingoPrintActionText
            return text
        },
        documentItems: {
            get() {
                return this.items
            },
            set(values) {
                this.$store.dispatch('document/dragSortItems', values)
            },
        },
        answer_key: {
            get() {
                return this.isBingo || this.isFlashcard ? 0 : this.document.show_answer_key
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    show_answer_key: value,
                })
            },
        },

        signUpTitle() {
            let text = this.worksheetTitle
            if (this.entityType === 'worksheet') text = this.worksheetTitle
            if (this.entityType === 'flashcard') text = this.flashcardTitle
            if (this.entityType === 'bingo') text = this.bingoTitle
            return text
        },
    },
    watch: {
        documentItems: {
            handler(oldItems, newItems) {
                if (oldItems.length < newItems.length) {
                    setTimeout(() => {
                        let elements = document.getElementsByClassName('item-frame')
                        let lastElement = elements[elements.length - 1]
                        if (lastElement) {
                            lastElement.scrollIntoView()
                        }
                    }, 100)
                }
            },
        },
        showActivities() {
            setTimeout(() => {
                let addQuestionElm = document.getElementById('add-question')
                if (addQuestionElm) {
                    addQuestionElm.scrollIntoView()
                }
            }, 100)
        },
        showAnswerKey(newValue) {
            if (this.isWorksheet) this.answer_key = newValue
        },
    },
    created() {
        window.addEventListener('keydown', this.addItemHandler)
    },
    methods: {
        setInstructionsData(updated) {
            this.onChange('index', {})

            if (this.isFlashcard) {
                this.$store.dispatch('document/setFlashcard', {
                    instruction: updated,
                })
            } else if (this.isBingo) {
                this.$store.dispatch('document/setBingo', {
                    instruction: updated,
                })
            }

            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        setPageSetupData(updated) {
            this.$store.dispatch('document/setFlashcard', {
                ...updated,
            })

            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        async showPrintableModal() {
            if (!this.isLoggedIn) {
                await this.$store.dispatch('abtests/initialize', {
                    completed: async () => {
                        await this.$store.dispatch('abtests/loadDomainCreateAccountHeaderTests')
                    },
                })
            }

            //log to Statsig a publish-button-click event
            this.logClickMakePrintable(this.documentType)

            if (this.showOnboardingModal) {
                this.user.user.request_no_email != null && this.user.user.occupation != null
                    ? this.showPrintingPaywall(true)
                    : this.initiateOnboarding()
            } else if (this.isLoggedIn && !this.isAdmin && this.showPremiumWall) {
                this.showPrintingPaywall(true)
            } else {
                this.$store.dispatch('document/setOnPublishing', true)
                if (!this.isLoggedIn) {
                    this.logSeenNewSignUpModal(this.entityType)
                    this.$modals.open('logIn')
                    this.$modals.logIn.signUpTitle = this.signUpTitle
                    this.$modals.logIn.print_panel = true
                    this.$modals.logIn.active_tab = 'sign_up'
                } else {
                    return this.$bvModal.show('printable-modal')
                }
            }
        },
        initiateOnboarding() {
            this.$store.dispatch('document/setOnPublishing', true)
            this.$store.dispatch('document/setOnboarding', true)
            this.onboardingComplete ? this.$bvModal.show('checkout-modal') : this.$bvModal.show('onboarding-modal')
        },
        dragStarted({ oldIndex }) {
            this.dragOn = oldIndex
        },
        dragEnded() {
            this.dragOn = -1
            this.closeWidget = false
        },
        intentToSave() {
            if (this.isLoggedIn) {
                if (!this.document.id) {
                    this.$modals.open('filename')
                } else {
                    this.$store.dispatch('document/updateDocumentRequest', this.documentModel)
                }
            } else {
                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
                this.$eventApi.create_event('sign-up-save-worksheet')
            }
        },
        showActivitySelectForm() {
            this.showActivities = true
        },
        addNewItem(type) {
            this.$store.dispatch('document/pushNewItemWithType', type)
        },
        inputType(type) {
            return type.replace(/_/g, '-')
        },
        getItemTitle(type) {
            return [...bingo_types, ...flashcard_types, ...worksheet_activities_types, ...element_types].find(
                (t) => t.key === type,
            )?.name
        },
        onChange(index, data) {
            if (this.isWorksheet && !data.hasOwnProperty('non_item')) {
                this.documentItems[index].update(data)
            }

            this.$store.dispatch('document/storeDocumentState')
            if (this.isLoggedIn) {
                this.$store.dispatch(this.persistAction)
            }

            if (this.isBingo) {
                this.$nextTick(() => {
                    this.$store.dispatch('document/paginateCallList', this.getWordBank)
                })
            }

            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', false)
            })
        },
        moveItemUp(index) {
            let items = copyObject(this.documentItems)
            let newIndex
            if (index) {
                newIndex = index - 1
            } else {
                newIndex = items.length - 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original worksheet items with our new one.
            this.documentItems = items
            this.$nextTick(() => {
                document.getElementById('handle-' + newIndex).focus()
            })
        },
        moveItemDown(index) {
            let items = copyObject(this.documentItems)

            let newIndex
            if (index == items.length - 1) {
                newIndex = 0
            } else {
                newIndex = index + 1
            }

            //get the item from its current index
            let item = items.splice(index, 1)[0]
            //place it in it's new index
            items.splice(newIndex, 0, item)

            //write over the original worksheet items with our new one.
            this.documentItems = items

            this.$nextTick(() => {
                document.getElementById('handle-' + newIndex).focus()
            })
        },
        getFocus(index) {
            return document.getElementById('worksheet-input-' + index)
        },
        setFocus(select) {
            let elem = this.getFocus(this.items.length - 1)

            if (elem) {
                elem.focus()
                elem.scrollIntoView()
                //select the text of the latest item on handwriting worksheets
                if (['handwriting', 'open_response'].includes(this.worksheet.type) && select) {
                    elem.select()
                }
            }
        },
        resetFocus() {
            if (this.$nav.active_panel == 'body') {
                let elem = this.getFocus(0)
                if (elem) {
                    elem.focus()
                    elem.blur()
                    document.getElementById('body-panel-content').scrollTop = 0
                }
            }
        },
        showDefinition() {
            return (
                !['word_search', 'handwriting', 'multiple_choice'].includes(this.worksheet.type) &&
                this.document.entity_type !== 'bingo'
            )
        },
        showTerm() {
            return !['open_response'].includes(this.worksheet.type)
        },
        sortItemList(field, direction) {
            this.$store.dispatch('document/sortItems', {
                field: field,
                direction: direction,
            })
        },
        resetItemSort() {
            this.itemSort = { field: false, dir: false }
        },
        sortItems() {
            if (this.itemSort.field) {
                if (this.itemSort.field == 'randomize') {
                    this.$store.dispatch('document/randomizeItems')
                } else {
                    this.sortItemList(this.itemSort.field, this.itemSort.dir)
                }

                this.justSorted = true
            }
        },
        tab(type, e, index) {
            if (index == this.items.length - 1) {
                if (
                    type == 'handwriting' ||
                    (type == 'definition' && this.showDefinition() && this.worksheet.type != 'fill_in_the_blank') ||
                    (type == 'term' &&
                        this.showDefinition() == false &&
                        !['handwriting', 'multiple_choice'].includes(this.worksheet.type)) ||
                    (type == 'term' && this.worksheet.type == 'fill_in_the_blank')
                ) {
                    e.preventDefault()
                    this.tabIndex = index + 1
                    this.addNewItem()
                }
            }
        },
        duplicateItem(index) {
            if (this.documentItems[index].type === 'word_bank') {
                return
            }

            this.$store.dispatch('document/duplicateItem', index).then(() => {
                this.currentWidget.focusedItem = this.documentItems[index + 1]
                this.currentWidget.openHeader = false
            })

            this.$nextTick(() => {
                this.$store.dispatch('document/renumberItems')
                this.$store.dispatch('document/scaleDocument', true)
            })

            this.$forceNextTick(() => {
                let elem = document.getElementById(`preview-${this.documentItems[index + 1].id}`)
                if (elem) {
                    elem.scrollIntoView({
                        behavior: 'smooth',
                    })
                    document.getElementById(`goto-id-${this.documentItems[index + 1].id}`).scrollIntoView({
                        behavior: 'smooth',
                    })
                }
            })
        },
        copyItem(index) {
            this.$store.dispatch('document/copyItem', index).then(() => {
                this.currentWidget.focusedItem = this.documentItems[index + 1]
                this.currentWidget.openHeader = false
            })
        },
        removeItem(index) {
            const focusedItem = this.documentItems[index]
            const original = new Activity(focusedItem.type)
            if (JSON.stringify(focusedItem.data) != JSON.stringify(original.data)) {
                if (focusedItem.type !== 'word_bank') {
                    wmAlert
                        .confirm({
                            title: 'Are you sure you want to delete?',
                            icon: 'question',
                            html: 'This will remove the selected section.',
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.$nextTick(() => {
                                    this.$store.dispatch('document/removeItem', index)
                                    this.rescaleDoc()
                                })
                            }
                        })

                    return
                }

                if (focusedItem.data.words.length) {
                    wmAlert
                        .confirm({
                            title: 'Are you sure you want to delete?',
                            icon: 'question',
                            html: 'This will remove the selected section.',
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                this.$nextTick(() => {
                                    this.$store.dispatch('document/removeItem', index)
                                    this.rescaleDoc()
                                    this.handleWordBankFib(focusedItem.data.id)
                                })
                            }
                        })

                    return
                }

                this.$store.dispatch('document/removeItem', index)
                this.handleWordBankFib(focusedItem.data.id)
                this.$nextTick(() => {
                    this.$store.dispatch('document/scaleDocument', true)
                })
            } else {
                this.$store.dispatch('document/removeItem', index)
                this.$nextTick(() => {
                    this.$store.dispatch('document/scaleDocument', true)
                })
            }
        },
        rescaleDoc() {
            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        handleWordBankFib(id) {
            this.documentItems.forEach((item) => {
                if (item.data.wordbank_id === id) {
                    item.data.include_wordbank = 0
                }
            })
        },
        clearItems() {
            this.$store.dispatch('document/clearItems')
            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        swapItem(index) {
            this.$store.dispatch('document/swapItem', index)
        },
        swapAllItems() {
            for (let i = 0; i < this.documentItems.length; i++) {
                this.swapItem(i)
            }
        },
        goto(e, item) {
            if (item?.type === 'page_break') {
                return
            }
            this.$emit('goto', e)
        },
        clearWidgets() {
            const content = this.isWorksheet
                ? 'This will revert your document to a blank template and cannot be undone.'
                : 'This will permanently delete all words and formatting. It cannot be undone.'

            wmAlert
                .confirm({
                    confirmButtonText: 'Clear All',
                    html: content,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        // clear document
                        if (this.isWorksheet) {
                            this.documentItems = []
                        } else if (!this.isBingo) {
                            this.documentItems = [
                                { definition: '', term: '', index: 0, term_image: '', definition_image: '' },
                            ]
                        }

                        this.$store.dispatch('document/resetDocumentHeader')
                        this.$store.dispatch('document/resetDocumentStyle')
                        if (this.isBingo) this.$store.dispatch('document/clearItems')

                        // Set focus to Worksheet header
                        this.$nextTick(() => {
                            this.$store.dispatch('document/setWidgetStatus', {
                                openHeader: !this.currentWidget.openHeader,
                                focusedItem: undefined,
                            })
                        })

                        let doc = {
                            title: `My ${this.document.entity_type.charAt(0).toUpperCase() + this.document.entity_type.slice(1)} Title`,
                            student_fields: ['Name', 'Date'],
                        }

                        this.$store.dispatch('document/setDocument', doc)

                        // Scroll document back to top
                        this.$emit('goto', {
                            ref: 'docHeader',
                            container: 'workspaceContainer',
                        })
                    }
                })
        },
        addItemHandler(e) {
            if (!e.repeat && ((e.metaKey && e.key === 'Enter') || (e.ctrlKey && e.key === 'Enter'))) {
                EventBus.$emit('keyboard-event-add-item')
            }
        },
        addItemDropdownOpened(e) {
            this.addItemDropDownOpen = e
        },
    },
})
</script>

<style lang="scss">
.fixed-body-style {
    top: 0;
    left: 15px;
    z-index: 100;
    position: sticky;
}

.swal2-icon {
    width: 40px;
    height: 40px;
}

.swal2-icon .swal2-icon-content {
    font-size: 25px;
}

.swal2-title {
    font-size: 20px;
}

.draging-card {
    opacity: 0;
}

.doc-control {
    background-color: #3c3d3d;
}

.draging-card {
    display: none;
}

.sortable-chosen.ghost-card {
    .widget-drawer,
    .frame-body,
    .frame-footer {
        display: none !important;
    }
}

.image-uploader {
    overscroll-behavior: contain;
}

@media only screen and (max-width: 768px) {
    .puzzle-form-section [class*='handle-']:before {
        inset: 0;
    }
}

.add-menu-open {
    padding-right: 20px !important;
}
</style>
