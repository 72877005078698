<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <VueEditor
                :ref="`widget_${index}`"
                v-model="titleInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Enter your header information."
                @text-change="updateData"
            ></VueEditor>
        </div>

        <div class="row w-100 m-0 my-2">
            <div class="col-md-6 p-0 d-flex flex-column">
                <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                <div>
                    <FontSelect
                        id="header-font"
                        ref="font-select"
                        :key="'section-header-font-' + fontFamily"
                        class="mt-0 mb-0 pb-0 w-100"
                        :font="fontFamily"
                        :options="false"
                        size="sm"
                        @changed="updateFont"
                    />
                </div>
            </div>
            <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                <label class="font-weight-bold mb-1 f-11 text-muted text-nowrap">Font Color</label>
                <b-form-input
                    v-model="itemData.color"
                    type="color"
                    :value="document.title_color"
                    size="sm"
                    @change="updateData"
                ></b-form-input>
            </div>
            <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold text-nowrap">Font Size</div>
                    <div class="text-secondary">
                        {{ parseInt(itemData.font_size) }}
                    </div>
                </label>
                <div class="d-flex f-10 align-items-center mb-0 mt-1">
                    <b-form-input
                        v-model="itemData.font_size"
                        class="flex-glow-1"
                        type="range"
                        min="6"
                        max="64"
                        :value="document.title_font_size"
                        @change="updateData"
                    ></b-form-input>
                </div>
            </div>
        </div>
        <div>
            <p class="mb-0 f-11 font-weight-bold text-muted mb-1">Alignment</p>
            <div class="d-flex">
                <div
                    class="rounded p-2 align-clues flex-shrink-0 pointer"
                    :class="{ 'domain-text-color': itemData.align === 'left' }"
                    @click="updateAlign('left')"
                >
                    <b-icon icon="text-left" class="text-lg"></b-icon>
                </div>

                <div
                    class="rounded p-2 align-clues flex-shrink-0 pointer mx-1"
                    :class="{ 'domain-text-color': itemData.align === 'center' }"
                    @click="updateAlign('center')"
                >
                    <b-icon icon="text-center" class="text-lg"></b-icon>
                </div>

                <div
                    class="rounded p-2 align-clues flex-shrink-0 pointer"
                    :class="{ 'domain-text-color': itemData.align === 'right' }"
                    @click="updateAlign('right')"
                >
                    <b-icon icon="text-right" class="text-lg"></b-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import { mapState, mapGetters } from 'vuex'
import FontSelect from '../../widgets/font-select.vue'

export default defineComponent({
    name: 'SectionHeader',
    components: {
        VueEditor,
        FontSelect,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            itemData: {
                ...this.data,
            },
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
            },
        }
    },
    computed: {
        ...mapState(['document', 'user']),
        ...mapGetters({
            entity: 'document/documentEntity',
            premiumFonts: 'document/getPremiumFonts',
            documentFonts: 'document/documentFonts',
            isLoggedIn: 'user/isLoggedIn',
        }),
        fontFamily: {
            get() {
                return this.itemData.font ? this.itemData.font : this.document.data.style.font
            },
            set(font) {
                this.itemData.font = font
                this.updateData()
            },
        },
        titleInput: {
            get() {
                return this.itemData.title
            },
            set(value) {
                this.itemData.title = value
            },
        },
    },
    mounted() {
        this.$refs[`widget_${this.index}`]?.quill.focus()
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
        updateAlign(value) {
            this.itemData.align = value
            this.updateData()
        },
        updateFont(event) {
            this.itemData.font = event.font
            this.updateData()
        },
        checkPremium(font) {
            return this.premiumFonts.includes(font)
        },
    },
})
</script>
