<!-- TODO:  Fix mutation of props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div class="card-body py-1">
            <div>
                <b-form-input
                    id="word-scramble-input"
                    :ref="`widget_${index}`"
                    :value="data.term"
                    placeholder="Type your word here"
                    @input="setValue('term', $event)"
                />
                <div class="d-flex mt-2 justify-content-between">
                    <div class="flex-shrink-0">
                        <div v-if="wordsCount > 1" class="d-flex flex-column">
                            <label class="font-weight-bold mb-0 f-11 text-muted">Scramble by word</label>
                            <b-form-checkbox
                                v-model="data.scramble_by_word"
                                class="f-13"
                                name="check-button"
                                :value="1"
                                :unchecked-value="0"
                                switch
                                @change="updateData"
                            ></b-form-checkbox>
                        </div>
                        <button class="btn btn-sm btn-primary mt-2" @click="shuffleWords()">Re-scramble</button>
                    </div>

                    <div>
                        <b-form-checkbox
                            id="checkbox-9"
                            v-model="data.include_wordbank"
                            class="f-14"
                            name="checkbox-9"
                            :disabled="data.term ? false : true"
                        >
                            Include in Word Bank
                        </b-form-checkbox>
                        <div
                            v-if="data.include_wordbank"
                            class="wordbanks-wrapper pl-4 mt-2 d-flex flex-wrap align-items-center"
                        >
                            <span
                                v-for="wordbank in allWordbanks"
                                :key="wordbank.id"
                                class="p-1 mr-1 pointer"
                                :class="wordbank.data.id === data.wordbank_id ? 'selected rounded' : ''"
                            >
                                <p
                                    class="p-2 m-0"
                                    :style="{
                                        backgroundColor: wordbank.data.color,
                                    }"
                                    @click="switchWordBank(wordbank.data.id)"
                                />
                            </span>
                            <span class="add-new p-1 d-flex pointer" @click="addNewWordbank()">
                                <b-icon icon="plus"></b-icon>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="btn-expand text-center">
                    <a href="#" size="sm" class="btn btn-circle" @click.prevent="showAdvancedOptions = !showAdvancedOptions">
                        <svg
                            viewBox="0 0 16 16"
                            width="1em"
                            height="1em"
                            focusable="false"
                            role="img"
                            aria-label="three dots"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            class="bi-three-dots b-icon bi"
                        >
                            <g>
                                <path
                                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                                ></path>
                            </g>
                        </svg>
                    </a>
                </div>

                <div v-if="showAdvancedOptions || data.type === WORD_SCRAMBLE_TYPE_MULTIPLE" class="mt-2">
                    <div class="mt-2 row">
                        <div
                            class="col-md-4"
                            :class="{
                                'col-md-6': data.type === WORD_SCRAMBLE_TYPE_MULTIPLE,
                            }"
                        >
                            <label class="font-weight-bold mb-1 f-11 text-muted">Answer Type</label>
                            <b-form-select
                                v-model="data.type"
                                label="Body Font"
                                :options="options"
                                size="sm"
                                class="mt-0 mb-2"
                            ></b-form-select>
                        </div>
                        <div v-if="data.type === WORD_SCRAMBLE_TYPE_LINES" class="col-md-4">
                            <label class="font-weight-bold mb-0 f-11 text-muted">Line Length</label>
                            <b-form-input v-model="data.line_length" type="range" min="10" max="100"></b-form-input>
                        </div>
                        <div v-if="data.type === WORD_SCRAMBLE_TYPE_LINES" class="col-md-4">
                            <label class="font-weight-bold mb-1 f-11 text-muted">Line Position</label>
                            <b-form-radio v-model="data.line_position" value="after" class="text-muted f-11 text-height-2">
                                After
                            </b-form-radio>
                            <b-form-radio v-model="data.line_position" value="below" class="text-muted f-11 text-height-2">
                                Underneath
                            </b-form-radio>
                        </div>
                    </div>
                    <div v-if="data.type === WORD_SCRAMBLE_TYPE_MULTIPLE">
                        <label class="font-weight-bold mb-1 f-11 text-muted my-2">Answer Options</label>
                        <MultiOptions v-model="multipleChoices" :correct-choice="getRightChoice()"></MultiOptions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MultiOptions from '../MultiOptions.vue'
import { mapGetters } from 'vuex'
import { WORD_SCRAMBLE_TYPE_LINES, WORD_SCRAMBLE_TYPE_MULTIPLE } from '../../store/helpers/documentHelpers'

export default {
    name: 'WordScramble',
    components: {
        MultiOptions,
    },
    props: ['data', 'index'],
    data() {
        return {
            WORD_SCRAMBLE_TYPE_LINES,
            WORD_SCRAMBLE_TYPE_MULTIPLE,
            options: [
                { value: 1, text: 'Line' },
                { value: 2, text: 'Blank' },
                { value: 3, text: 'Multiple choice' },
            ],
            content: '',
            showAdvancedOptions: false,
            customToolbar: [['bold', 'italic', 'underline'], [], []],
        }
    },
    computed: {
        ...mapGetters({
            allWordbanks: 'document/allWordbanks',
        }),
        wordsCount() {
            return this.data.term?.split(' ').length || 0
        },
        multipleChoices: {
            get() {
                return this.data.choices
            },
            set(choices) {
                // eslint-disable-next-line vue/no-mutating-props
                this.data.choices = choices
                const correctAnswer = choices.find((c) => c.correct)
                if (correctAnswer) {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.data.term = correctAnswer.answer
                }

                this.$emit('change', this.data)
            },
        },
    },
    watch: {
        'data.include_wordbank': {
            deep: true,
            handler(value) {
                this.updateWordbank()
                if (!value) {
                    this.removeFromWordbank()
                }
            },
        },
    },
    mounted() {
        this.$refs[`widget_${this.index}`].focus()
        this.updateWordbank()
    },
    methods: {
        setColorFormating() {
            Quill.format('color', this.$refs.colors.value)
            this.$emit('change', this.data)
        },
        updateData() {
            this.$emit('change', this.data)
        },
        setValue(field, value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            // eslint-disable-next-line vue/no-mutating-props
            this.data[field] = text

            if (this.data.include_wordbank) {
                const target_id = this.data.wordbank_id
                    ? this.data.wordbank_id
                    : this.allWordbanks.length
                      ? this.allWordbanks[0].data.id
                      : undefined
                this.$store.dispatch('document/updateWordbank', {
                    index: this.index,
                    words: [text],
                    target_id,
                })
                // eslint-disable-next-line vue/no-mutating-props
                this.data.wordbank_id = target_id
            }
            this.$emit('change', this.data)
        },
        shuffleWords(times) {
            if (!times) {
                times = 5
            }

            let promises = []
            let delay = 1
            for (let i = 0; i < times; i++) {
                promises.push(
                    new Promise((resolve) => {
                        setTimeout(() => {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.data.shuffle_seed = Math.floor(Math.random() * 9999)
                            resolve()
                        }, delay)
                        delay += 100
                    }),
                )
            }

            // when all the items have been saved set is_loading to false
            Promise.all(promises).then(() => {
                this.$emit('change', this.data)
            })
        },
        switchWordBank(wordbank_id) {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.include_wordbank = true
            this.$store.dispatch('document/updateWordbank', {
                index: this.index,
                words: [this.data.term],
                target_id: wordbank_id,
            })
        },
        updateWordbank() {
            if (this.data.include_wordbank) {
                const target_id = this.data.wordbank_id
                    ? this.data.wordbank_id
                    : this.allWordbanks.length
                      ? this.allWordbanks[0].data.id
                      : undefined
                this.$store.dispatch('document/updateWordbank', {
                    index: this.index,
                    words: [this.data.term],
                    target_id,
                })
            }
        },
        removeFromWordbank() {
            this.$store.dispatch('document/removeFromWordbank', {
                wordbank_id: this.data.wordbank_id,
                index: this.index,
            })
        },
        addNewWordbank() {
            this.$store.dispatch('document/addNewWordbank', {
                index: this.index,
                words: [this.data.term],
                wordsArray: [this.data.term],
            })
        },
        getRightChoice() {
            return {
                answer: this.data.term,
                correct: true,
            }
        },
    },
}
</script>

<style lang="scss">
@import 'Scss/base.scss';
.wordbanks-wrapper {
    .add-new {
        border-radius: 50%;
        background: $gray-500;
        color: white;
    }
    span {
        p {
            border-radius: 50%;
        }
        &.selected {
            border: 1px solid #212121;
        }
    }
}
</style>
