<template>
    <div>
        <b-form-group class="mb-0">
            <b-form-textarea
                :id="'worksheet-input-' + index"
                rows="1"
                max-rows="6"
                class="rounded-top-only"
                placeholder="Enter a sentence without blanks."
                :value="item.definition"
                @input="setValue('definition', $event)"
            />
            <div v-if="item.definition && item.definition.includes('_')" class="border-left border-right pt-1 px-2 pb-3">
                <b-form-invalid-feedback class="text-sm" :state="false">
                    Write your sentence without the blank. Enter the word(s) you would like replaced with blanks below.
                </b-form-invalid-feedback>
            </div>
        </b-form-group>
        <div class="swap-container">
            <a href="#" @click.prevent="swapItem()">
                <b-icon icon="shuffle" class="rounded bg-secondary p-1" variant="light" aria-label="flip values"></b-icon>
            </a>
        </div>
        <b-form-group class="mb-0" :state="mismatch_validation">
            <b-form-textarea
                rows="1"
                max-rows="6"
                class="rounded-bottom-only"
                placeholder="Word(s) to be replaced with blanks"
                :value="item.term"
                :aria-invalid="mismatch_validation"
                @input="setValue('term', $event)"
            />
            <b-form-invalid-feedback class="text-sm" :state="mismatch_validation">
                You have words in the bottom that are not in your sentence on top.
            </b-form-invalid-feedback>
        </b-form-group>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
    name: 'FillInTheBlankItemForm',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState(['worksheet']),
        ...mapGetters({ worksheetItems: 'document/worksheetItems' }),
        item() {
            return this.worksheetItems[this.index]
        },
        mismatch_validation() {
            if (!this.item.term || this.item.term.length == 0) {
                return null
            }

            let words = { ...this.item }.term.split('\n')
            let definition = { ...this.item }.definition.toLowerCase()
            for (let i = 0; i < words.length; i++) {
                let word = words[i].toLowerCase().trim()
                if (definition.indexOf(word) < 0) {
                    return false
                }
            }

            return null
        },
    },
    methods: {
        setValue(field, value) {
            let update = { index: this.index, item: {} }
            update['item'][field] = value
            this.$store.dispatch('document/updateItem', update)
        },
        swapItem() {
            this.$store.dispatch('document/updateItem', {
                index: this.index,
                item: {
                    term: this.item.definition,
                    definition: this.item.term,
                },
            })
        },
    },
})
</script>
