<template>
    <div>
        <div class="mb-0 d-flex">
            <VueEditor
                :ref="'flashcard_front_word_' + index"
                v-model="itemTerm"
                class="flashcard-input front"
                :class="{
                    'hidden-header': focusedEditor !== frontQuill,
                }"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Front (optional)"
                @focus="getFrontFocus"
                @input="getFrontFocus"
            ></VueEditor>
            <InlineImageBtn
                class="front"
                btn-class="flashcard-inline-image-btn front"
                :image-id="item.term_image"
                :input-index="index"
                column="term"
                @click="getFrontFocus"
                @deleted="item.term_image = ''"
            />
        </div>
        <div class="swap-container" tabindex="-1" style="z-index: 10">
            <a href="#" tabindex="-1" @click.prevent="swapItem()">
                <b-icon
                    icon="shuffle"
                    class="rounded bg-secondary p-1"
                    variant="light"
                    aria-label="flip values"
                    tabindex="-1"
                ></b-icon>
            </a>
        </div>
        <div class="mb-0 d-flex">
            <VueEditor
                :ref="'flashcard_back_word_' + index"
                v-model="itemDefinition"
                class="flashcard-input back"
                :class="{
                    'hidden-header': focusedEditor !== backQuill,
                }"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Back (optional)"
                @focus="getBackFocus"
                @input="getBackFocus"
            ></VueEditor>
            <InlineImageBtn
                class="back"
                :btn-class="[{ 'border-top-0': hideBorder(item) }, 'flashcard-inline-image-btn back']"
                :image-id="item.definition_image"
                :input-index="index"
                column="definition"
                @click="getBackFocus"
                @deleted="item.definition_image = ''"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import { mapGetters, mapState } from 'vuex'
import InlineImageBtn from '../../components/AddInlineImageButton.vue'
import InlineImagesMixin from '../../mixins/InlineImages'

export default defineComponent({
    name: 'FlashcardItemForm',
    components: {
        VueEditor,
        InlineImageBtn,
    },
    mixins: [InlineImagesMixin],
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        var self = this
        return {
            frontQuill: undefined,
            backQuill: undefined,
            focusedEditor: undefined,
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
                modules: {
                    keyboard: {
                        bindings: {
                            enter: {
                                key: 13,
                                handler: function () {
                                    self.onNextFocus()
                                    return false
                                },
                            },
                            tab: {
                                key: 9,
                                handler: function () {
                                    self.onNextFocus()
                                    return false
                                },
                            },
                        },
                    },
                },
            },
        }
    },
    computed: {
        ...mapState(['worksheet']),
        ...mapGetters({
            documentItems: 'document/documentItems',
            currentWidget: 'document/currentWidget',
            inlineImages: 'document/flashcardInlineImages',
        }),
        itemTerm: {
            get() {
                return this.item.term
            },
            set($event) {
                this.setValue('term', $event)
            },
        },
        itemDefinition: {
            get() {
                return this.item.definition
            },
            set($event) {
                this.setValue('definition', $event)
            },
        },
        item() {
            return this.documentItems[this.index]
        },
    },
    watch: {
        currentWidget: {
            immediate: true,
            handler(val) {
                if (val.focusedItem) {
                    setTimeout(() => {
                        let element =
                            val.focusedItem.target === 'term'
                                ? this.$refs['flashcard_front_word_' + val.focusedItem.index]
                                : this.$refs['flashcard_back_word_' + val.focusedItem.index]
                        if (typeof element !== 'undefined') {
                            element?.quill.root.focus()
                            let el = element?.quill.root
                            let range = document.createRange()
                            let sel = window.getSelection()
                            if (range) {
                                range.setStart(el, 1)
                                range.collapse(true)
                                if (sel) {
                                    sel.removeAllRanges()
                                    sel.addRange(range)
                                }
                            }
                        }
                    }, 100)
                }
            },
        },
    },
    mounted() {
        this.frontQuill = this.$refs[`flashcard_front_word_${this.index}`]?.quill
        this.backQuill = this.$refs[`flashcard_back_word_${this.index}`]?.quill
        this.focusedEditor = this.frontQuill
    },
    methods: {
        setValue(field, value) {
            let update = { index: this.index, item: {} }
            update['item'][field] = value
            this.$store.dispatch('document/updateItem', update)
        },
        swapItem() {
            this.$store.dispatch('document/updateItem', {
                index: this.index,
                item: {
                    term: this.item.definition,
                    definition: this.item.term,
                    term_image: this.item.definition_image,
                    definition_image: this.item.term_image,
                },
            })
        },
        focusFrontWord() {
            let el = undefined
            let quill = undefined
            if (this.itemTerm) {
                el = this.frontQuill?.clipboard.quill.root
                quill = this.frontQuill
            } else if (this.itemDefinition) {
                el = this.backQuill?.clipboard.quill.root
                quill = this.backQuill
            } else {
                el = this.frontQuill?.clipboard.quill.root
                quill = this.frontQuill
            }
            if (el) {
                let range = document.createRange()
                let sel = window.getSelection()
                if (range) {
                    range.setStart(el, 1)
                    range.collapse(true)
                    if (sel) {
                        sel.removeAllRanges()
                        sel.addRange(range)
                    }
                }
                quill?.focus()
            }
        },
        onNextFocus() {
            if (this.focusedEditor === this.frontQuill) {
                this.backQuill?.focus()
            } else if (this.focusedEditor === this.backQuill) {
                this.$emit('focus-next')
            }
        },
        getFrontFocus() {
            this.focusedEditor = this.frontQuill
            let elem = document.getElementById(`flashcard-front-${this.index}`)

            const elemBoundings = elem.getBoundingClientRect()
            if (elemBoundings.top < 50) {
                elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
            } else {
                if (window.innerHeight - elemBoundings.top < 150) {
                    elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }
        },
        getBackFocus() {
            this.focusedEditor = this.backQuill
            let elem = document.getElementById(`flashcard-back-${this.index}`)
            const elemBoundings = elem.getBoundingClientRect()
            if (elemBoundings.top < 50) {
                elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
            } else {
                if (window.innerHeight - elemBoundings.top < 150) {
                    elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
            }
        },
    },
})
</script>

<style lang="scss">
.flashcard-input {
    width: 90%;
    .ql-toolbar.ql-snow {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        border: none;
        padding: 0 !important;
        .ql-formats {
            margin-right: 0;
            button {
                outline: none !important;
            }
        }
    }
    &.hidden-header {
        .ql-toolbar.ql-snow {
            display: none;
        }
    }
    &.front .ql-container {
        border-top: 1px solid #ccc !important;
        border-top-right-radius: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        border-right: none;
    }
    &.back .ql-container {
        border-top: 0 !important;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0.25rem;
        border-right: none;
    }
}
.flashcard-inline-image-btn {
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
    &.front {
        border-bottom-right-radius: 0;
    }
    &.back {
        border-top-right-radius: 0;
    }
}
.inline-image-btn.front {
    .resource-box {
        bottom: 37.5px;
    }
}
.inline-image-btn.back {
    .resource-box {
        bottom: -0.5px;
    }
}
</style>
