import uuidv1 from 'uuid/v1'

const INITIAL_WIDTH = 120

export default class ImageModel {
    constructor(name, docIndex, iconObject = null) {
        this.id = uuidv1()
        this.name = name
        this.objectId = null
        this.uploading = true
        this.lockAspectRatio = true
        this.width = INITIAL_WIDTH
        this.x = 0
        this.y = 0
        this.ratio = true
        this.ratioChanged = 0
        this.docIndex = docIndex

        if (iconObject !== null) {
            this.iconObject = new IconObject(iconObject)
            this.is_icon = true
            this.objectId = iconObject.id
            this.uploading = false
            this.downloaded = false
            if (!this.name) {
                this.name = 'Icon'
            }
        }
    }
}

export class IconObject {
    constructor(iconObject) {
        this.id = iconObject.id
        this.is_premium = iconObject.is_premium || iconObject.our_premium
        this.styles = iconObject?.styles
    }
}
