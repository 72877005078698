<template>
    <div class="card-body pb-2 pt-0">
        <div>
            <div class="d-flex justify-content-between mb-2">
                <b-form-checkbox
                    id="title_visible"
                    v-model="title_visible"
                    class="subtitle font-weight-bold text-muted"
                    name="title_visible"
                    value="1"
                    unchecked-value="0"
                >
                    {{ isWorksheet ? 'Worksheet' : '' }} Title
                </b-form-checkbox>
                <div
                    v-if="isWorksheet || isFlashcard"
                    v-b-tooltip.hover
                    title="Show the header info at the top of every page."
                    class="col-md-6 d-flex align-items-center p-0 justify-content-end"
                >
                    <label class="f-11 font-weight-bold text-muted mb-0 cursor-pointer" for="every_page">Every Page</label>
                    <b-form-checkbox
                        id="every_page"
                        v-model="omniStudent"
                        class="f-13 ml-1"
                        name="check-button"
                        value="1"
                        unchecked-value="0"
                        switch
                        :disabled="disableEveryPage"
                    ></b-form-checkbox>
                </div>
            </div>

            <div v-if="loading" class="d-flex justify-content-center align-items-center min-h-76px">
                <Loader :dark="true" size="30px"></Loader>
            </div>

            <div v-else class="min-h-76px input-box position-relative" @drop="(e) => e.preventDefault()">
                <VueEditor
                    ref="worksheet_header"
                    v-model="titleContent"
                    :editor-toolbar="customToolbar"
                    :editor-options="editorSettings"
                    :placeholder="titlePlaceholder ? titlePlaceholder : ''"
                    @focus="selectAll"
                    @text-change="onTextChange"
                ></VueEditor>

                <ToggleButton v-if="hasHeaderStylesFeature" v-model="showTitleStyles" class="position-absolute toggle-btn">
                    <div class="d-flex">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-palette-fill mr-1"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                            />
                        </svg>

                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            stroke="currentColor"
                            fill="currentColor"
                        >
                            <defs>
                                <path
                                    id="text-path-0"
                                    d="M 3.7090001106262207 15.151793241500854 Q 8.00025224685669 0.847618818283081 12.291504383087158 15.151793241500854"
                                />
                            </defs>
                            <rect
                                x="1"
                                y="1"
                                width="14"
                                height="14"
                                style="fill: none; stroke-miterlimit: 7; stroke-linejoin: round"
                            />
                            <text
                                style="
                                    font-family: Arial, sans-serif;
                                    font-size: 12.8px;
                                    stroke-miterlimit: 1;
                                    white-space: pre;
                                "
                                transform="matrix(0.438371, 0.898794, -0.898794, 0.438371, 15.445608, 2.922325)"
                            >
                                <textPath xlink:href="#text-path-0">A</textPath>
                            </text>
                        </svg>
                    </div>
                </ToggleButton>
            </div>

            <div v-if="showTitleStyles || showStyleRow" class="row w-100 m-0 my-2">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="header-font"
                            ref="font-select"
                            :key="'header-font'"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="title_font"
                            :options="false"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted text-nowrap">Font Color</label>
                    <b-form-input v-model="title_color" type="color" :value="document.title_color" size="sm"></b-form-input>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <span class="font-weight-bold text-nowrap">Font Size</span>
                        <span class="text-secondary">
                            {{ parseInt(document.title_font_size) }}
                        </span>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            v-model="title_font_size"
                            class="flex-glow-1"
                            type="range"
                            min="6"
                            max="64"
                            :value="document.title_font_size"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-1" />
        <div>
            <div class="row mx-0 mt-0 mb-2 input-box">
                <b-form-checkbox
                    id="checkbox-2"
                    v-model="student_info_visible"
                    class="flex-grow-1 subtitle font-weight-bold text-muted"
                    name="checkbox-2"
                    value="1"
                    unchecked-value="0"
                >
                    Student Info
                </b-form-checkbox>

                <ToggleButton v-if="hasHeaderStylesFeature" v-model="showStudentInfoStyles" class="toggle-btn mr-2">
                    <div class="d-flex">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-palette-fill mr-1"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                            />
                        </svg>

                        <svg
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            stroke="currentColor"
                            fill="currentColor"
                        >
                            <defs>
                                <path
                                    id="text-path-0"
                                    d="M 3.7090001106262207 15.151793241500854 Q 8.00025224685669 0.847618818283081 12.291504383087158 15.151793241500854"
                                />
                            </defs>
                            <rect
                                x="1"
                                y="1"
                                width="14"
                                height="14"
                                style="fill: none; stroke-miterlimit: 7; stroke-linejoin: round"
                            />
                            <text
                                style="
                                    font-family: Arial, sans-serif;
                                    font-size: 12.8px;
                                    stroke-miterlimit: 1;
                                    white-space: pre;
                                "
                                transform="matrix(0.438371, 0.898794, -0.898794, 0.438371, 15.445608, 2.922325)"
                            >
                                <textPath xlink:href="#text-path-0">A</textPath>
                            </text>
                        </svg>
                    </div>
                </ToggleButton>
            </div>
            <StudentForm />

            <div v-if="showStudentInfoStyles || showStyleRow" class="row w-100 m-0 my-2">
                <div class="col-md-6 p-0 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Font Type</label>
                    <div>
                        <FontSelect
                            id="student-info-title"
                            :key="'student-info-font' + document.student_info_font"
                            class="mt-0 mb-0 pb-0 w-100"
                            field="student_info_font"
                            :options="false"
                            size="sm"
                        />
                    </div>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="font-weight-bold mb-1 f-11 text-muted text-nowrap">Font Color</label>
                    <b-form-input
                        type="color"
                        :value="document.student_info_color"
                        size="sm"
                        @input="
                            $store.dispatch('document/setDocument', {
                                student_info_color: $event,
                            })
                        "
                        @change="$store.dispatch('document/storeDocumentState')"
                    ></b-form-input>
                </div>
                <div class="col-md-3 p-0 pl-3 d-flex flex-column">
                    <label class="mb-1 f-11 text-muted d-flex justify-content-between">
                        <span class="font-weight-bold text-nowrap">Font Size</span>
                        <span class="text-secondary">
                            {{ parseInt(document.student_info_font_size) }}
                        </span>
                    </label>
                    <div class="d-flex f-10 align-items-center mb-0 mt-1">
                        <b-form-input
                            class="flex-glow-1"
                            type="range"
                            min="6"
                            max="48"
                            :value="document.student_info_font_size"
                            @input="
                                $store.dispatch('document/setDocument', {
                                    student_info_font_size: $event,
                                })
                            "
                            @change="persistFontSize"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'
import StudentForm from '../student-form.vue'
import { mapGetters, mapState } from 'vuex'
import ToggleButton from '../ToggleButton.vue'
import StatsigEvent from '../../common/StatsigEvent'
import { filenameFromTitle } from '../../common/helpers'
import FontSelect from '../../widgets/font-select.vue'
import { getDocumentTitle } from '../../store/modules/document/document'

const getTitlePlaceHolder = (entity_type = null) => {
    let placeholder = 'Add a title!'
    switch (entity_type) {
        case 'worksheet':
            placeholder = 'Give your worksheet a title!'
            break
        case 'flashcard':
            placeholder = 'Give your flashcards a title!'
            break
        default:
            break
    }
    return placeholder
}

export default defineComponent({
    name: 'WorksheetHeader',
    components: {
        VueEditor,
        StudentForm,
        ToggleButton,
        FontSelect,
    },
    data() {
        return {
            showWorksheetTitle: true,
            showStudentInfoStyles: false,
            showTitleStyles: false,
            showStudentInfo: true,
            content: '',
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                imageDrop: false,
                formats: ['bold', 'italic', 'underline', 'script'],
            },
            titlePlaceholder: null,
            updateInterval: null,
            titleMaxLength: 300,
        }
    },
    computed: {
        ...mapState(['document', 'user']),
        ...mapGetters({
            style: 'document/documentStyle',
            omni_student: 'document/omniStudent',
            isLoggedIn: 'user/isLoggedIn',
            isWorksheet: 'document/isWorksheet',
            isFlashcard: 'document/isFlashcard',
            entityType: 'document/entityType',
            currentWidget: 'document/currentWidget',
            flashcard: 'document/flashcard',
            hasHeaderStylesFeature: 'abtests/headerStyles',
            loadingAbs: 'abtests/loading',
            maxLength: 'document/filenameMaxLength',
            allDocumentTypes: 'document/allDocumentTypes',
        }),
        titleContent: {
            get() {
                return this.content
            },
            set(value) {
                this.content = value
                if (this.content !== this.document.title) {
                    let doc = { title: this.content }
                    if (!this.document.filenameEdited) {
                        doc = {
                            title: this.content,
                            filename: filenameFromTitle(this.content, this.document.entity_type),
                        }
                    }
                    this.$store.dispatch('document/setDocument', doc)
                    this.$nextTick(() => {
                        this.persistHeaderChange()
                    })
                }
                window.doc.title = this.content
            },
        },
        omniStudent: {
            get() {
                return this.omni_student
            },
            set(value) {
                this.$store.dispatch('document/setOmniStudent', parseInt(value))
            },
        },
        title_visible: {
            get() {
                return this.document.title_visible
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_visible: Number(value),
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        student_info_visible: {
            get() {
                return this.document.student_info_visible
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    student_info_visible: Number(value),
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        title_font: {
            get() {
                return this.$store.state.document.title_font
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_font: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        title_color: {
            get() {
                return this.$store.state.document.title_color
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_color: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },

        title_font_size: {
            get() {
                return this.$store.state.document.title_font_size
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    title_font_size: value,
                })

                this.$emit('change', {
                    non_item: true,
                })
            },
        },
        flashcardHeaderHeight() {
            return this.document.flashcardHeaderHeight
        },
        disableEveryPage() {
            return 10 - this.flashcardHeaderHeight < this.flashcard.flashcard_item_height
        },
        showStyleRow() {
            return !this.loadingAbs && !this.hasHeaderStylesFeature
        },
        loading() {
            return !this.isLoggedIn && this.loadingAbs
        },
        hasSetTitle() {
            const docTypes = this.allDocumentTypes.find((dt) => dt.key === this.document.entity_type)
            const defaultTitle = getDocumentTitle(false, this.document.entity_type, docTypes, true)

            return !defaultTitle.includes(this.content)
        },
    },
    watch: {
        'document.title': {
            deep: true,
            handler(value) {
                if (!this.content) {
                    this.content = value
                }
            },
        },
        loadingAbs(current) {
            if (!current) {
                this.$store.dispatch('document/setTitle')
                this.$nextTick(() => {
                    this.selectAll()
                })
            }
        },
        currentWidget: {
            immediate: true,
            handler(val) {
                if (val.openHeader)
                    this.$nextTick(() => {
                        this.selectAll()
                    })
            },
        },
        showStudentInfoStyles() {
            this.toggleStudentInfoStyles()
        },
        showTitleStyles() {
            this.toggleTitleStyles()
        },
    },
    created() {
        this.content = this.document.title
        this.titlePlaceholder = getTitlePlaceHolder(this.document?.entity_type)
    },
    mounted() {
        if (this.entityType === 'bingo') {
            this.persistHeaderChange(true)
        }
    },
    methods: {
        selectAll() {
            if (!this.content || this.hasSetTitle) return
            const quill = this.$refs.worksheet_header?.quill

            this.$nextTick(() => {
                if (quill) {
                    quill.setSelection(0, this.content.length)
                }
            })
        },
        toggleTitleStyles() {
            let title = 'Header Title Styles ' + (!this.showTitleStyles ? 'Hidden' : 'Shown')
            this.logStyleButtonClick(title)
        },
        toggleStudentInfoStyles() {
            let title = 'Student Info Styles ' + (!this.showStudentInfoStyles ? 'Hidden' : 'Shown')
            this.logStyleButtonClick(title)
        },
        logStyleButtonClick(title = null) {
            if (!this.hasHeaderStylesFeature) return
            this.$store.dispatch('abtests/logStatsigEvent', {
                event: StatsigEvent.HEADER_STYLES_BTN_CLICK,
                value: 'Has-Used Feature',
                payload: {
                    title: title ?? 'Header styles shown',
                },
            })
        },
        persistFontSize() {
            this.$store.dispatch('document/storeDocumentState')
            this.$nextTick(() => {
                this.$store.dispatch('document/scaleDocument', true)
            })
        },
        persistHeaderChange(immediate = false) {
            if (!this.content) return
            if (this.updateInterval) clearInterval(this.updateInterval)

            let interval = immediate ? 1 : 500

            this.updateInterval = setInterval(() => {
                if (this.document.filename !== '') {
                    this.$emit('change', {
                        non_item: true,
                    })
                }
                clearInterval(this.updateInterval)
            }, interval)
        },
        onTextChange() {
            const quill = this.$refs.worksheet_header.quill
            const len = quill.getLength()
            if (len > this.titleMaxLength) {
                quill.deleteText(this.titleMaxLength, len)
            }
        },
    },
})
</script>

<style lang="scss" scoped>
@import 'Scss/base.scss';

.subtitle {
    font-size: $font-size-sm;
}

.input-box {
    .toggle-btn {
        right: 12px;
        top: 5px;

        svg {
            width: 16px;
        }
    }
}

.min-h-76px {
    min-height: 76px;
}
</style>
