<template>
    <div>
        <b-form-group class="mb-0">
            <b-form-input
                :id="'worksheet-input-' + index"
                type="text"
                class="rounded-top-only"
                placeholder="Word(s) in left column"
                :value="item.term"
                @input="setValue('term', $event)"
            />
        </b-form-group>
        <div class="swap-container">
            <a href="#" @click.prevent="swapItem()">
                <b-icon icon="shuffle" class="rounded bg-secondary p-1" variant="light" aria-label="flip values"></b-icon>
            </a>
        </div>
        <b-form-group class="mb-0">
            <b-form-input
                type="text"
                class="rounded-bottom-only"
                placeholder="Matching word(s) in right column"
                :value="item.definition"
                @input="setValue('definition', $event)"
            />
        </b-form-group>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
    name: 'MatchingItemForm',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({ worksheetItems: 'document/worksheetItems' }),
        item() {
            return this.worksheetItems[this.index]
        },
    },
    methods: {
        setValue(field, value) {
            let update = { index: this.index, item: {} }
            update['item'][field] = value
            this.$store.dispatch('document/updateItem', update)
        },
        swapItem() {
            this.$store.dispatch('document/updateItem', {
                index: this.index,
                item: {
                    term: this.item.definition,
                    definition: this.item.term,
                },
            })
        },
    },
})
</script>
