<template>
    <div class="flex-grow-1">
        <div class="d-flex align-items-center justify-content-between">
            <div class="save-document-input-wrap rounded flex-grow-1 mr-1 py-0 px-2 d-flex align-items-center text-truncate">
                <label class="mb-0" for="file-name-input">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#666"
                        class="bi bi-folder"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"
                        />
                    </svg>
                </label>
                <b-input
                    id="file-name-input"
                    ref="save"
                    v-model="documentFilename"
                    class="save-document-input py-0 w-100 px-2"
                    type="text"
                    :maxlength="maxLength"
                ></b-input>
            </div>
            <div>
                <button v-if="!editing" class="btn btn-success btn-sm" :disabled="filenameEmpty" @click="saveDocument">
                    {{ document.id ? 'Update' : 'Save' }}
                </button>

                <button v-else class="btn btn-success btn-sm mr-1" @click="saveDocument">Saving...</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { filenameFromTitle } from '../common/helpers'
import DocumentHelper from '../mixins/DocumentHelper'
import StatsigHelper from '../mixins/StatsigHelper'

export default defineComponent({
    name: 'Save',
    mixins: [DocumentHelper, StatsigHelper],
    data() {
        return {
            filename: '',
            editing: false,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            persistAction: 'document/immediatePersistAction',
            maxLength: 'document/filenameMaxLength',
        }),
        ...mapState(['user', 'document', 'subscription']),
        documentFilename: {
            get() {
                return this.filename
            },
            set(value) {
                this.filename = value.trim().substring(0, this.maxLength)

                this.$store.dispatch('document/setDocument', {
                    filename: this.filename,
                })

                this.$store.dispatch('document/setDocument', {
                    filenameEdited: true,
                })

                this.$nextTick(() => {
                    this.$store.dispatch('document/storeDocumentState')
                })
            },
        },
        nameFromTitle() {
            const titleContent = this.document.filenameEdited ? this.document.filename : this.document.title
            return filenameFromTitle(titleContent, this.document.entity_type).substring(0, this.maxLength)
        },
        filenameEmpty() {
            return !this.documentFilename || (this.documentFilename && this.documentFilename.trim() === '')
        },
        fileAndTitleValue() {
            return `${this.document.title}|${this.document.filename}`
        },
    },
    watch: {
        fileAndTitleValue() {
            if (this.documentFilename.trim() !== '') this.filename = this.nameFromTitle
        },
    },
    mounted() {
        let timer

        this.$refs.save.$el.addEventListener('keyup', () => {
            clearTimeout(timer)

            timer = setTimeout(() => {
                if (!this.isLoggedIn) return

                if (this.documentFilename.trim() === '') this.filename = this.nameFromTitle

                this.editing = true

                this.$store.dispatch(this.persistAction, true).finally(() => {
                    this.editing = false
                })
            }, 2000)
        })
    },
    async created() {
        if (window.copying) {
            this.setInitialDocumentValues()
        }

        if (this.document.filename) {
            this.$store.dispatch('document/setDocument', {
                filenameEdited: true,
            })

            this.filename = this.document.filename
        } else {
            let upperCasedEntity = this.document.entity_type.replace(/^\w/, (c) => c.toUpperCase())

            this.filename = '[Untitled ' + upperCasedEntity + ']'
        }

        await this.$store.dispatch('document/setDocument', {
            filename: this.filename,
        })
    },
    methods: {
        async saveDocument() {
            if (this.isLoggedIn) {
                this.editing = true

                await this.$store.dispatch(this.persistAction, true).finally(() => {
                    this.editing = false
                })
            } else {
                let message = ''

                if (this.isWorksheet) {
                    message = 'worksheet_filename_save_button'
                }

                if (this.isFlashcard) {
                    message = 'flashcard_filename_save_button'
                }

                if (this.isBingo) {
                    message = 'bingo_filename_save_button'
                }

                await this.$eventApi.create_event('sign-up-open', message)

                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
                await this.logSeenEmailOverlay()
            }
        },
    },
})
</script>

<style lang="scss" scoped>
.save-document-input-wrap {
    border: 2px solid darken(#fff, 60%);
}
.save-document-input {
    background-color: darken(#fff, 80%);
    color: darken(#fff, 20%);
    font-weight: 700;
    &:hover,
    &:focus {
        border: none;
        outline: none;
        box-shadow: none;
    }
}
</style>
