<template>
    <div>
        <b-form-group class="mb-0">
            <b-form-textarea
                :id="'worksheet-input-' + index"
                rows="1"
                max-rows="6"
                placeholder="Enter your prompt here."
                :value="item.term"
                @input="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { term: $event },
                    })
                    $store.dispatch('document/storeDocumentState')
                "
            />
        </b-form-group>

        <div class="grid-2 pt-2">
            <label>Columns</label>
            <div class="text-sm text-right">{{ item.num_of_columns }}</div>
        </div>

        <b-form-input
            type="range"
            step="1"
            min="1"
            max="4"
            :value="item.num_of_columns"
            @input="
                $store.dispatch('document/updateItem', {
                    index: index,
                    item: {
                        num_of_columns: Number($event),
                    },
                })
            "
        />

        <div>
            <Draggable v-bind="{ handle: '.handle' }" v-model="options" class="multiple-choice-options">
                <b-form-group v-for="(option, optionIndex) in options" :key="'mc-' + index + '-' + optionIndex">
                    <b-input-group class="m-0">
                        <b-input-group-prepend>
                            <div class="input-group-text">
                                <a
                                    :id="'mc-handle-' + index + '-' + optionIndex"
                                    v-b-tooltip.hover
                                    href="#"
                                    title="Reorder"
                                    class="handle text-secondary"
                                    aria-label="drag"
                                    @click.prevent=""
                                    @keyup.up="moveItemUp(optionIndex)"
                                    @keyup.down="moveItemDown(optionIndex)"
                                >
                                    <b-icon icon="grip-horizontal"></b-icon>
                                </a>
                            </div>
                        </b-input-group-prepend>
                        <b-form-input
                            :ref="'multiple_choice_option-' + index + '-' + optionIndex"
                            class="flex-grow-1"
                            type="text"
                            placeholder="Answer Choice"
                            :value="option.answer"
                            @input="updateOption(optionIndex, $event)"
                        />

                        <b-input-group-append>
                            <div class="input-group-text">
                                <b-form-checkbox
                                    :id="'multiple_choice_correct_answer-' + index + '-' + optionIndex"
                                    v-b-tooltip.hover
                                    type="checkbox"
                                    :value="1"
                                    title="Correct Answer"
                                    :checked="option.correct"
                                    @change="setCorrect(optionIndex, +$event)"
                                ></b-form-checkbox>

                                <a
                                    v-b-tooltip.hover
                                    href="#"
                                    title="Remove Option"
                                    class="text-danger"
                                    aria-label="Delete"
                                    @click="removeOption(optionIndex)"
                                >
                                    <b-icon icon="x-circle-fill"></b-icon>
                                </a>
                            </div>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </Draggable>

            <div class="item-addon-section last padding-top-15">
                <b-form-input
                    class="flex-grow-1"
                    type="text"
                    placeholder="New Answer Choice"
                    @keydown="pushOption($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Draggable from 'vuedraggable'
import { mapGetters, mapState } from 'vuex'
import { copyObject } from '../../helpers/copyObject'

export default defineComponent({
    name: 'MultipleChoiceItemForm',
    components: {
        Draggable,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({ worksheetItems: 'document/worksheetItems' }),
        item() {
            return this.worksheetItems[this.index]
        },
        options: {
            get() {
                return this.item.options
            },
            set(options) {
                this.$store.dispatch('document/sortMultipleChoiceOptions', {
                    index: this.index,
                    options: options,
                })
            },
        },
    },
    methods: {
        moveItemUp(index) {
            let options = copyObject(this.options)

            let newIndex
            if (index) {
                newIndex = index - 1
            } else {
                newIndex = options.length - 1
            }

            //get the item from its current index
            let item = options.splice(index, 1)[0]
            //place it in it's new index
            options.splice(newIndex, 0, item)

            //write over this.options with our new options
            this.options = options
            this.$nextTick(() => {
                document.getElementById('mc-handle-' + this.index + '-' + newIndex).focus()
            })
        },
        moveItemDown(index) {
            let options = copyObject(this.options)

            let newIndex
            if (index == options.length - 1) {
                newIndex = 0
            } else {
                newIndex = index + 1
            }

            //get the item from its current index
            let item = options.splice(index, 1)[0]
            //place it in it's new index
            options.splice(newIndex, 0, item)

            //write over this.options with our new options
            this.options = options

            this.$nextTick(() => {
                document.getElementById('mc-handle-' + this.index + '-' + newIndex).focus()
            })
        },
        tab(e, optionIndex) {
            if (optionIndex == this.worksheetItems[this.index].options.length - 1) {
                e.preventDefault()
                this.pushOption()
            }
        },
        setCorrect(optionIndex, correct) {
            this.$store.dispatch('document/setCorrectMultipleChoiceOption', {
                index: this.index,
                optionIndex: optionIndex,
                correct: correct,
            })
            this.$store.dispatch('document/storeDocumentState')
        },
        pushOption(evt) {
            if (typeof evt == 'undefined' || evt.key != 'Tab') {
                this.$store.dispatch('document/pushMultipleChoiceOption', this.index)
                let index = this.worksheetItems[this.index].options.length - 1

                this.$nextTick(() => {
                    this.$refs['multiple_choice_option-' + this.index + '-' + index][0].$el.focus()
                })
            }
        },
        updateOption(optionIndex, value) {
            this.$store.dispatch('document/updateMultipleChoiceOption', {
                index: this.index,
                optionIndex: optionIndex,
                value: value,
            })
            this.$store.dispatch('document/storeDocumentState')
        },
        removeOption(optionIndex) {
            this.$store.dispatch('document/removeMultipleChoiceOption', {
                index: this.index,
                optionIndex: optionIndex,
            })
            this.$store.dispatch('document/storeDocumentState')
            if (!this.worksheetItems[this.index].options.length) {
                this.pushOption(index)
            }
        },
    },
})
</script>
