<template>
    <div class="card-body pb-1 pt-0">
        <div class="d-flex align-items-center">
            <div class="w-75 pr-4">
                <label class="font-weight-bold d-flex py-1 justify-content-between mb-0 f-11 text-muted">
                    <span>Height</span>
                    <span class="font-weight-normal">{{ Math.round(itemData.height * 10) / 10 }} in</span>
                </label>

                <b-form-input v-model="lineHeight" type="range" :min="0.125" :max="8.3" :step="0.01"></b-form-input>
            </div>

            <div class="w-25">
                <label class="font-weight-bold mb-0 f-11 text-muted">Box Outline</label>
                <b-form-checkbox
                    v-model="boxOutline"
                    class="f-14 text-muted ml-1 mr-n2 pb-3"
                    tabindex="-1"
                    name="box_outline"
                    switch
                ></b-form-checkbox>
            </div>
        </div>

        <div v-if="itemData.box_outline" class="btn-expand text-center mt-2">
            <a href="#" size="sm" class="btn btn-circle" @click.prevent="showOptions = !showOptions">
                <svg
                    viewBox="0 0 16 16"
                    width="1em"
                    height="1em"
                    focusable="false"
                    role="img"
                    aria-label="three dots"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi-three-dots b-icon bi"
                >
                    <g>
                        <path
                            d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
                        ></path>
                    </g>
                </svg>
            </a>
        </div>

        <div v-if="itemData.box_outline && showOptions">
            <div class="d-flex mt-2">
                <div class="flex-grow-1 mr-4">
                    <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                        <div class="font-weight-bold">Border</div>
                    </label>
                    <div class="d-flex f-10 justify-content-between mb-0">
                        <div>Thin</div>
                        <div>Thick</div>
                    </div>

                    <b-form-input
                        v-model="itemData.border_width"
                        type="range"
                        :min="1"
                        :max="10"
                        :step="1"
                        @change="updateData"
                    ></b-form-input>
                </div>

                <div class="w-25">
                    <label class="font-weight-bold d-block mb-0 f-11 text-muted">Color</label>
                    <input v-model="itemData.line_color" type="color" class="d-flex h-50 mt-1" @change="updateData" />
                </div>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted">Border Style</label>
                <div class="d-flex row">
                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="groove"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="groove"></div>
                            Groove
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="dashed"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="dashed"></div>
                            Dashed
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="solid"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="solid"></div>
                            Solid
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="outset"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="outset"></div>
                            Outset
                        </div>
                    </div>
                </div>
                <div class="d-flex row mt-2">
                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="dotted"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="dotted"></div>
                            Dotted
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="double"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="double"></div>
                            Double
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="ridge"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="ridge"></div>
                            Ridge
                        </div>
                    </div>

                    <div class="d-flex align-items-center col-md-3">
                        <b-form-radio
                            v-model="itemData.line_style"
                            name="line-style"
                            value="inset"
                            @change="updateData"
                        ></b-form-radio>
                        <div class="f-11 text-muted">
                            <div class="inset"></div>
                            Inset
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BlankSpace',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
            showOptions: false,
        }
    },
    computed: {
        lineHeight: {
            get() {
                return this.itemData.height
            },
            set(value) {
                this.itemData.height = value
                this.updateData()
            },
        },
        boxOutline: {
            get() {
                return this.itemData.box_outline
            },
            set(value) {
                this.itemData.box_outline = value
                this.updateData()
                if (!value) {
                    this.showOptions = value
                }
            },
        },
    },
    methods: {
        updateData() {
            this.closedAdvanced = !this.closedAdvanced
            this.$emit('change', this.itemData)
        },
        closeOptions() {
            this.closedAdvanced = !this.closedAdvanced
        },
    },
})
</script>
