<template>
    <div :id="'goto-id-' + idIndex" :data-id="idIndex" @click="itemClicked(gotoId, 'workspaceContainer')">
        <div class="item-frame shadow-sm bg-white rounded" :class="{ 'border-primary-left': opened, 'mt-2': !item }">
            <div
                class="handle-widget frame-header cursor-pointer text-md d-flex justify-content-between align-items-center pl-2 pr-2"
                :class="[{ draggable }, { 'active-item': opened }]"
                :style="
                    item && item.type === 'word_bank'
                        ? opened
                            ? { backgroundColor: item.data.color + '80' }
                            : { backgroundColor: item.data.color }
                        : {}
                "
                @click.prevent="toggleWidget()"
            >
                <span v-if="numberable">
                    <button
                        v-if="numbering && numbering.number > 0"
                        :id="uniqueNumberingMenu"
                        class="btn-square btn-numbering rounded-sm px-2 py-1 cursor-normal text-sm"
                        @click.stop="show = !show"
                    >
                        <span
                            class="number"
                            :class="{
                                'text-light-gray': numbering && numbering.hidden,
                            }"
                            v-html="
                                $store.getters['document/format_number'](
                                    (numbering && numbering.number) || 1,
                                    numbering && numbering.format,
                                )
                            "
                        ></span>
                    </button>

                    <b-popover :target="uniqueNumberingMenu" :show.sync="show" triggers="click blur" placement="right">
                        <div class="mb-2">
                            <b-form-checkbox
                                v-model="numbering_restarted"
                                switch
                                :disabled="skip_numbering"
                                @change="renumberItems"
                            >
                                Restart at 1
                            </b-form-checkbox>

                            <b-form-checkbox v-model="skip_numbering" switch @change="toggleNumberVisibility">
                                Skip Numbering
                            </b-form-checkbox>
                        </div>

                        <p class="text-muted f-11 mb-0 mt-3 font-weight-bold">NUMBER FORMATS</p>
                        <div class="grid-2">
                            <b-form-radio
                                v-for="value in numberFormatOptions"
                                :key="value.key"
                                v-model="numberFormat"
                                name="number_format"
                                :value="value.key"
                            >
                                <span v-html="value.name"></span>
                                <PremiumMarker
                                    v-if="value.isPremium && !hasFeature('formatting', document, document.entity_type)"
                                    :name="'number-format|' + value.name"
                                ></PremiumMarker>
                            </b-form-radio>
                        </div>
                    </b-popover>
                </span>
                <span
                    class="frame-title font-weight-bold d-flex align-items-center"
                    :class="[{ highlight: opened, pointer: !document.is_published }]"
                >
                    {{ title }}
                    <span
                        v-if="wordbankColor"
                        class="ml-2 p-2 border border-light rounded-circle"
                        :style="{ 'background-color': wordbankColor }"
                    ></span>
                </span>
                <a v-if="gotoId !== 'docHeader'" :id="'handle-' + index" title="Reorder" aria-label="drag" @click.prevent="">
                    <b-icon v-if="!addDisabled" v-b-tooltip.hover class="btn-option" icon="three-dots" />
                </a>
                <svg
                    v-if="item && itemHidden"
                    v-b-tooltip.hover
                    title="Hidden"
                    class="text-muted pointer"
                    fill="none"
                    height="20"
                    width="20"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                </svg>
            </div>
            <div class="frame-body">
                <div v-if="opened" class="frame-content text-left p-2">
                    <slot></slot>
                </div>
                <div
                    v-if="!addDisabled && !opened"
                    class="pointer"
                    @click.prevent="toggleWidget(gotoId, 'workspaceContainer')"
                >
                    <div v-if="item">
                        <div
                            v-if="item.data && item.data.subtitle"
                            class="subtitle p-1 pl-3 pr-3"
                            v-html="item && item.data && item.data.subtitle"
                        ></div>
                        <div
                            v-if="item.data && item.data.instruction"
                            class="subtitle p-1 pl-3 pr-3"
                            v-html="item && item.data && item.data.instruction"
                        ></div>
                        <div
                            v-if="item.data && item.data.title"
                            class="subtitle p-1 pl-3 pr-3"
                            v-html="item && item.data && item.data.title"
                        ></div>
                    </div>
                    <div v-else>
                        <div class="subtitle p-1 pl-3 pr-3" v-html="document.title"></div>
                    </div>
                </div>
            </div>
            <div v-if="opened && !hideOptions" class="frame-footer d-flex justify-content-end align-items-center py-1">
                <span v-if="!hideOptions && item?.type !== 'word_bank'" class="mr-3 pointer" @click="$emit('on-duplicate')">
                    <svg
                        v-b-tooltip.hover
                        title="Duplicate"
                        class="text-muted"
                        height="20"
                        width="20"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                        />
                    </svg>
                </span>
                <b-form-checkbox
                    v-if="!item"
                    v-b-tooltip.hover
                    class="mr-2"
                    title="Hide"
                    @change="handleHide(Number($event))"
                ></b-form-checkbox>

                <div v-else class="mr-3">
                    <svg
                        v-if="!itemHidden"
                        v-b-tooltip.hover
                        title="Hide"
                        class="text-muted pointer"
                        fill="none"
                        height="20"
                        width="20"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        @click="handleHide"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                    </svg>

                    <svg
                        v-else
                        v-b-tooltip.hover
                        fill="none"
                        viewBox="0 0 24 24"
                        title="Show"
                        class="text-muted"
                        height="20"
                        width="20"
                        stroke="currentColor"
                        @click="handleHide"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        />
                    </svg>
                </div>

                <div class="remove-option">
                    <a
                        v-if="removeable"
                        v-b-tooltip.hover
                        href="#"
                        title="Remove Item"
                        class="text-danger text-lg pr-2 pl-2 d-flex align-items-center"
                        aria-label="Delete"
                        @click.prevent="$emit('on-remove')"
                    >
                        <svg fill="none" viewBox="0 0 24 24" height="20" width="20" stroke="currentColor">
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div v-show="opened || showAddWidget" v-if="!addDisabled" :id="`add_widgets_${uniqueRef}`" class="position-relative">
            <!-- Close button -->
            <div
                v-if="!opened && showAddWidget"
                class="close-add-item-widget pointer d-flex justify-content-center align-items-center rounded-circle d-none"
                style="z-index: 6"
                @click="showAddWidget = false"
            >
                <svg viewBox="0 0 20 20" fill="currentColor" height="20" width="20">
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>
            <!-- Add widget -->
            <AddItem
                :open-close-main-menu="changeShow()"
                :item-index="index"
                :item="item ? item : null"
                @added="newItemAdded"
                @showFalse="closePopOverOnMenuOpen"
                @addItemDropdownOpened="addItemDropdownOpened"
            />
        </div>

        <div
            v-show="!opened && !showAddWidget"
            v-if="!addDisabled"
            :id="`add_widgets_button_${uniqueRef}`"
            class="add-item text-center pointer w-100 mb-1"
            style="z-index: 0"
        >
            <div class="plus" @click="openAddWidget">
                <svg viewBox="0 0 20 20" fill="currentColor" height="20" width="20">
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import uniqueId from 'lodash.uniqueid'
import { mapGetters, mapState } from 'vuex'
import premiumMarker from '../premium-marker.vue'
import Activity from '../../store/models/Activity'
import { numberingFormats } from '../../objects/Document'
import EditPayWall from '../../mixins/EditPayWall'
import AddItem from './AddItem.vue'

export const swapAddItemsWithButton = (uniqueRef = null) => {
    document.querySelectorAll('[id^="add_widgets_"]').forEach((widget) => {
        widget.style.display = 'none'
    })

    document.querySelectorAll('[id^="add_widgets_button_"]').forEach((widget) => {
        widget.style.display = 'block'
    })

    if (uniqueRef != null) {
        let widget = document.getElementById('add_widgets_' + uniqueRef)
        let newwidgetBtn = document.getElementById('add_widgets_button_' + uniqueRef)

        if (widget) widget.style.display = 'block'
        if (newwidgetBtn) newwidgetBtn.style.display = 'none'
    }
}

export default {
    name: 'ItemFrame',
    components: {
        premiumMarker,
        AddItem,
    },
    mixins: [EditPayWall],
    props: {
        title: String,
        gotoId: String,
        editable: Boolean,
        removeable: Boolean,
        visible: Boolean,
        opened: Boolean,
        hideOptions: Boolean,
        draggable: Boolean,
        index: Number,
        data: Object,
        item: Activity,
        addDisabled: Boolean,
    },
    data() {
        return {
            uniqueNumberingMenu: null,
            numberFormatOptions: numberingFormats,
            showAddWidget: false,
            numbering_restarted: false,
            skip_numbering: false,
            show: false,
        }
    },
    computed: {
        ...mapState(['user', 'document']),
        ...mapGetters({
            instructions: 'document/documentInstructions',
        }),
        uniqueRef() {
            return 'add_widgets_' + this.item?.id
        },
        numberFormat: {
            get() {
                return this.numbering?.format || 0
            },
            set(value) {
                this.$store.dispatch('document/setItemsFormatAt', {
                    at: this.index,
                    format: value,
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        numbering: {
            get() {
                return this.item?.numbering
            },
        },
        wordbankColor: {
            get() {
                return this.item?.getWordBankColor()
            },
        },
        numberable: {
            get() {
                return this.item?.numberable
            },
        },
        subtitle: {
            get() {
                return this.item?.getSubtitle()
            },
        },
        idIndex() {
            return this.index === undefined ? 'header' : this.item.id
        },
        itemHidden: {
            get() {
                return this.item?.hide
            },
            async set(value) {
                await this.$store.dispatch('document/toggleItemVisibility', { index: this.index, hide: value })
                this.renumberItems()
            },
        },
    },
    created() {
        this.uniqueNumberingMenu = uniqueId('numbering_menu_')
        this.numbering_restarted = this.item?.numbering?.numbering_restarted
        this.skip_numbering = this.item?.numbering?.hidden
    },
    methods: {
        openAddWidget() {
            swapAddItemsWithButton(this.uniqueRef)
            const widget = document.getElementById(`add_widgets_${this.uniqueRef}`)
            if (widget) widget.style.display = 'block'
        },
        changeShow() {
            if (this.show === true) {
                return this.show
            }
        },
        closePopOverOnMenuOpen(e) {
            this.show = e[0]
        },
        toggleNumberVisibility() {
            this.numbering.hidden = !this.numbering.hidden
            if (this.numbering.hidden) {
                if (this.numbering_restarted) {
                    this.$emit('on-restart-next')
                }
                this.numbering_restarted = false
                this.numbering.numbering_restarted = false
            }
            this.$store.dispatch('document/renumberItems')
        },
        renumberItems() {
            this.numbering.numbering_restarted = !this.numbering.numbering_restarted
            this.$store.dispatch('document/renumberItems')
        },
        showPopOverAndHideMainMenu() {
            this.show = !this.show
        },
        handleHide(value = null) {
            if (this.item) {
                this.itemHidden = !this.itemHidden
                return
            }

            this.$store.state.document.title_visible = !value
            this.$store.state.document.student_info_visible = !value
            this.$store.dispatch('document/renumberItems')
        },
        goto(refName, containerRef) {
            this.$emit('goto', { ref: refName, container: containerRef })
        },
        toggleWidget() {
            this.openAddWidget()
            this.$emit('on-edit')
        },
        addItemDropdownOpened(value) {
            this.$emit('addItemDropdownOpened', value)
        },
        newItemAdded(payload = null) {
            this.showAddWidget = false
            this.hideSelf(payload)
        },
        getWidgetItems(uniqueRef) {
            return {
                widget: document.getElementById('add_widgets_' + uniqueRef),
                newwidget: document.getElementById('add_widgets_button_' + uniqueRef),
            }
        },
        hideSelf(itemAdded) {
            let current = this.getWidgetItems(this.uniqueRef)
            if (this.uniqueRef != null) {
                if (current.widget) current.widget.style.display = 'none'
                if (current.newwidget) current.newwidget.style.display = 'block'
            }
            if (itemAdded) {
                this.$nextTick(() => {
                    this.showNew(itemAdded)
                })
            }
        },
        showNew(itemAdded) {
            let newItem = this.getWidgetItems('add_widgets_' + itemAdded.id)

            if (newItem.widget) newItem.widget.style.display = 'block'
            if (newItem.newwidget) newItem.newwidget.style.display = 'none'
        },
        itemClicked(gotoId, elementClass) {
            this.goto(gotoId, elementClass)
            this.openAddWidget()
        },
    },
}
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.item-frame {
    text-align: center;
    background-color: white;
    position: relative;
    width: 100%;

    .frame-header {
        position: relative;
        background-color: $gray-300;
        color: $gray-900;
        padding: 8px 0;
        border-radius: 0.25rem 0.25rem 0 0;
        min-height: 44px;

        &:hover {
            // background-color: #F4F2FF;
            color: $primary;
        }

        .btn-numbering {
            min-width: 28px;
            min-height: 28px;
            display: flex;
        }

        .btn-option {
            position: absolute;
            top: 0px;
            left: 50%;
            color: $gray-900;
            transform: translateX(-50%);
            font-size: 16px;
        }

        .frame-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .btn-edit {
            position: absolute;
            top: 50%;
            right: 10px;
            color: $gray-900;
            transform: translateY(-50%);
            cursor: pointer;
        }

        &.highlight {
            font-size: 18px;
            color: black;
        }

        &.draggable {
            cursor: move;
        }
    }

    .frame-body {
        .subtitle {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            text-align: left;
        }

        .subtitle > p {
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            text-align: left;
        }

        .frame-content {
            padding: 0 8px;
        }
    }

    .frame-footer {
        border-top: 1px solid $gray-400;
        padding: 0;

        .remove-option {
            border-left: 1px solid $gray-400;
        }
    }

    .btn-expand {
        position: relative;
        padding: 2px 0;
        z-index: 1;

        .btn-circle {
            width: 24px;
            height: 24px;
            padding: 2px 0px;
            border-radius: 50%;
            text-align: center;
            font-size: 13px;
            background-color: $gray-500;
            color: white;
            border: none;
        }

        &:before {
            content: ' ';
            width: 40%;
            height: 1px;
            background-color: $gray-500;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
        }
    }
}

.menu_item {
    cursor: pointer;
}

.add-item {
    position: relative;
    color: $gray-500;

    &:is(:hover) {
        color: $primary;
    }

    .plus {
        &:before {
            content: '';
            width: 45%;
            height: 2px;
            background-color: $gray-500;
            position: absolute;
            top: 52%;
            left: 0;
            z-index: -1;
        }

        svg {
            vertical-align: middle;
        }

        &:after {
            content: '';
            width: 45%;
            height: 2px;
            background-color: $gray-500;
            position: absolute;
            top: 52%;
            right: 0;
            z-index: -1;
        }

        &:hover {
            &:before {
                background-color: $primary;
            }

            &:after {
                background-color: $primary;
            }
        }
    }
}

.close-add-item-widget {
    position: absolute;
    right: 10px;
    top: -8px;
    color: #c9c9c9;
    background: $gray-900;
    opacity: 1;
    height: 22px;
    width: 22px;
    z-index: 10;

    &:hover {
        color: red;
        opacity: 1;
    }
}
</style>
