<template>
    <div>
        <b-modal id="printable-modal" :size="showingPremiumWall ? 'lg' : 'md'" hide-footer hide-header centered>
            <div id="print-panel">
                <svg
                    class="position-absolute close-icon text-muted pointer"
                    title="close"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click="$bvModal.hide('printable-modal')"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <div class="p-3">
                    <h6 class="font-weight-bold text-center mb-3">Almost done! Finalize your worksheet:</h6>

                    <fieldset class="fieldset mb-2">
                        <legend>Privacy</legend>
                        <div class="d-flex mb-3 align-items-center justify-content-center">
                            <!-- <div class="d-flex f-12 mr-2">
                                    <svg width="12" height="16" class="mr-1" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 5.375H9.75V3.875C9.75 1.805 8.07 0.125 6 0.125C3.93 0.125 2.25 1.805 2.25 3.875V5.375H1.5C0.675 5.375 0 6.05 0 6.875V14.375C0 15.2 0.675 15.875 1.5 15.875H10.5C11.325 15.875 12 15.2 12 14.375V6.875C12 6.05 11.325 5.375 10.5 5.375ZM3.75 3.875C3.75 2.63 4.755 1.625 6 1.625C7.245 1.625 8.25 2.63 8.25 3.875V5.375H3.75V3.875ZM10.5 14.375H1.5V6.875H10.5V14.375ZM6 12.125C6.825 12.125 7.5 11.45 7.5 10.625C7.5 9.8 6.825 9.125 6 9.125C5.175 9.125 4.5 9.8 4.5 10.625C4.5 11.45 5.175 12.125 6 12.125Z" fill="#333333"/>
                                    </svg>
                                    Privacy:
                                </div> -->
                            <b-form-radio
                                v-model="isPrivate"
                                name="private"
                                :value="0"
                                class="mr-5"
                                :disabled="hasPremiumImages"
                            >
                                Public
                            </b-form-radio>

                            <b-form-radio v-model="isPrivate" name="private" :value="1">
                                <span class="d-inline-flex justify-content-center align-items-center">
                                    <span class="mr-2">Private</span>
                                    <PremiumMarker
                                        v-if="!hasFeature('private', document)"
                                        name="private"
                                        :popover="false"
                                    ></PremiumMarker>
                                </span>
                            </b-form-radio>
                        </div>
                        <p v-if="hasPremiumImages" class="text-center f-12 font-italic">
                            Uploading images requires saving worksheet as Private.
                        </p>
                    </fieldset>

                    <!-- if the user has premium features and they're not a premium subscriber, show the pitch -->
                    <div v-if="showPremiumWall">
                        <OneClickUpgrade :page="entityType" :printing="true"></OneClickUpgrade>
                    </div>

                    <div v-else>
                        <!-- Else, show the remainder of the publish form -->
                        <fieldset v-if="!user.user.occupation" class="fieldset mb-4">
                            <legend>I use My Worksheet Maker as a:</legend>
                            <OccupationForm :error="errors.get('occupation')"></OccupationForm>
                        </fieldset>

                        <fieldset class="fieldset mb-4">
                            <legend class="mb-2">Subject</legend>
                            <!-- <span class="d-flex align-items-center mb-1">
                                    <svg class="mr-1" width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.5 0.625L0.875 5.875L4.375 7.7825V13.0325L10.5 16.375L16.625 13.0325V7.7825L18.375 6.82875V12.875H20.125V5.875L10.5 0.625ZM16.4675 5.875L10.5 9.13L4.5325 5.875L10.5 2.62L16.4675 5.875ZM14.875 11.9913L10.5 14.38L6.125 11.9913V8.73625L10.5 11.125L14.875 8.73625V11.9913Z" fill="#515151"/>
                                    </svg>

                                    Subject:
                                </span> -->
                            <b-form-group :invalid-feedback="errors.get('subject')" :state="!errors.has('subject')">
                                <b-form-select v-model="worksheetSubject" :options="document.subjects"></b-form-select>

                                <b-form-input
                                    v-if="document.subject === 'Other'"
                                    placeholder="Subject"
                                    type="text"
                                    class="mt-2"
                                    :value="document.subject_other"
                                    maxlength="255"
                                    @input="
                                        $store.dispatch('document/setDocument', {
                                            subject_other: $event,
                                        })
                                    "
                                    @change="$store.dispatch('document/storeDocumentState')"
                                />
                            </b-form-group>
                        </fieldset>

                        <fieldset class="fieldset">
                            <legend>Grade Level</legend>
                            <b-form-checkbox-group
                                v-model="worksheetGradeLevels"
                                stacked
                                @change="$store.dispatch('document/storeDocumentState')"
                            >
                                <div class="grid-3 mb-3">
                                    <div v-for="(grades, idx1) in gradeLevels" :key="'grades' + idx1">
                                        <b-form-checkbox
                                            v-for="(grade, idx2) in grades"
                                            :key="'grade' + idx1 + '-' + idx2"
                                            :value="grade"
                                        >
                                            {{ grade }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </b-form-checkbox-group>

                            <template v-if="errors.has('grade_levels')">
                                <div
                                    class="invalid-feedback mb-3"
                                    style="display: block"
                                    v-html="errors.get('grade_levels')"
                                ></div>
                            </template>
                        </fieldset>

                        <b-button
                            :key="print_tries"
                            :disabled="print_clicked"
                            block
                            size="lg"
                            variant="primary"
                            @click.once="makePrintable()"
                        >
                            <span>Make Printable!</span>
                        </b-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import Errors from '../libraries/errors'
import OccupationForm from './Occupation.vue'
import EditPayWall from '../mixins/EditPayWall'
import StatsigEvent from '../common/StatsigEvent'
import { mapGetters, mapState } from 'vuex'
import wmAlert from '../helpers/wmAlerts'
import Common from '../mixins/Common'
import OneClickUpgrade from './OneClickUpgrade.vue'
import StatsigHelper from '../mixins/StatsigHelper'
import { getUndownloadedIcons, hasIconImages, hasNonIconImages, iconImages } from '../helpers/jsonObjectHelper'
import ImageUploadApi from '../apis/ImageUploadApi'

export default defineComponent({
    name: 'PrintableModal',
    components: { OccupationForm, OneClickUpgrade },
    mixins: [EditPayWall, Common, StatsigHelper],
    data() {
        return {
            print_tries: 0,
            print_clicked: false,
            white_label_switch_key: 0,
            pdf_is_loading: false,
            showOccupationForm: false,
            occupation: null,
            gradeLevels: [
                ['Pre-K', 'K', '1', '2', '3', '4'],
                ['5', '6', '7', '8', '9', '10'],
                ['11', '12', 'College', 'Adult', '65+'],
            ],
            errors: new Errors(),
            premium_wall_event_fired: false, // this is here so we don't fire a bunch of premium wall seen events from simple shifts.
            authUrl: window?.authUrl,
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
            isAdmin: 'user/isAdmin',
            onboardingComplete: 'user/onboardingComplete',
            publishTime: 'user/publishTime',
            getPremiumFeatures: 'document/getPremiumFeatures',
            isDocumentPublishable: 'document/isPublishable',
            isBingo: 'document/isBingo',
            isFlashcard: 'document/isFlashcard',
            isWorksheet: 'document/isWorksheet',
            persistAction: 'document/immediatePersistAction',
            premiumFont: 'abtests/premiumFont',
            worksheetImages: 'document/worksheetImages',
            flashcardImages: 'document/flashcardImages',
            bingoImages: 'document/bingoImages',
            entityType: 'document/entityType',
            documentItems: 'document/documentItems',
            worksheetAllImages: 'document/worksheetAllImages',
            bingoAllImages: 'document/bingoAllImages',
            flashcardAllImages: 'document/flashcardAllImages',
        }),
        ...mapState(['user', 'document', 'subscription']),
        images() {
            if (this.entityType === 'worksheet') return this.worksheetAllImages
            if (this.entityType === 'flashcard') return this.flashcardAllImages
            if (this.entityType === 'bingo') return this.bingoAllImages
            return []
        },
        premiumImages() {
            // should return personal images and premium iconfinder images
            // todo: this should be updated after iconfinder implemented
            return this.images
        },
        hasPremiumImages() {
            return this.premiumImages?.length > 0 && hasNonIconImages(this.premiumImages)
        },
        documentFilename() {
            return this.document.filename ? this.document.filename : this.document.title.replace(/(<([^>]+)>)/gi, '')
        },
        worksheetSubject: {
            get() {
                return this.document.subject
            },
            set(value) {
                this.$store.dispatch('document/setDocument', { subject: value })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        isPrivate: {
            get() {
                return this.document.is_private
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    is_private: Number(value),
                })
                this.$store.dispatch('document/storeDocumentState')
            },
        },
        worksheetGradeLevels: {
            get() {
                return this.document.grade_levels || []
            },
            set(value) {
                this.$store.dispatch('document/setDocument', {
                    grade_levels: value,
                })
            },
        },
        showingPremiumWall() {
            return this.showPremiumWall && this.isLoggedIn && this.onboardingComplete
        },
    },
    watch: {
        isLoggedIn(value) {
            this.$nextTick(() => {
                if (value && !this.isWorksheet && this.showPremiumWall && this.onboardingComplete) {
                    this.showCheckoutModal(true)
                }
            })
        },
    },
    created() {
        this.showOccupationForm = this.user.user.occupation
        // make a non-reactive occupation field
        this.occupation = this.user.user.occupation
    },
    mounted() {
        if (!this.document.subject || !this.document.grade_levels) {
            this.$store.dispatch('document/loadLatestPublishSettings')
        }
    },
    methods: {
        handleClickGoPremium() {
            this.$bvModal.hide('printable-modal')
            this.showCheckoutModal(true)
            this.$eventApi.create_event('plan_selection_overlay_seen', 'paywall')

            if (this.getPremiumFeatures.includes('font')) {
                this.$store.dispatch('abtests/logStatsigEvent', {
                    event: StatsigEvent.GO_FONT_PREMIUM,
                    value: this.premiumFont,
                })
            }
        },
        revertToBasic() {
            if (this.premiumImages.length >= 3) {
                wmAlert
                    .confirm({
                        html: `This will delete ${this.premiumImages.length} images from your document.`,
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('document/removePremiumFeatures')
                        }
                    })
            } else {
                this.$store.dispatch('document/removePremiumFeatures')
            }
        },
        setFilename() {
            this.errors.clear()
            if (this.filename === '') {
                this.errors.set('filename', 'Please enter a name for your document.')
            }
            if (!this.errors.any()) {
                this.$store.dispatch('document/setDocument', {
                    filename: this.filename,
                })
                this.$store.dispatch(this.persistAction).then(() => this.$modals.close('filename'))
            }
        },
        makePrintable() {
            this.print_clicked = true
            if (this.isLoggedIn) {
                let falsify = this.documentItems
                let booleanChange = falsify.filter((i) => i.type === 'blank_space')
                booleanChange.map((i) => (i.data.show_line = false))
                this.publish()
            } else {
                this.$modals.open('logIn')
                this.$modals.logIn.active_tab = 'sign_up'
            }
        },
        goPremium() {
            if (this.isLoggedIn) {
                this.$bvModal.hide('printable-modal')
                this.showCheckoutModal(true)
                this.$eventApi.create_event('go-premium-publish-panel-published')
            } else {
                this.$modals.logIn.is_visible = true
                this.$modals.logIn.active_tab = 'sign_up'
                this.$eventApi.create_event('go-premium-print-panel-published|not-logged-in')
            }
        },
        async publish() {
            try {
                this.errors.clear() // clear existing errors
                this.errors.record(this.isDocumentPublishable)
                if (this.errors.any()) {
                    this.print_clicked = false
                    this.print_tries++
                    //scroll back to the top if there are any errors
                    document.getElementById('print-panel').parentElement.scrollTop = 0
                    document.getElementById('print-panel').scrollTop = 0
                    return
                }

                //in order to publish, this worksheet must be saved.
                await this.$store.dispatch('document/setIsPublishing', true)
                await this.$store.dispatch(this.persistAction)
                let undownloadedIcons = getUndownloadedIcons(iconImages(this.images))
                if (hasIconImages(this.images) && undownloadedIcons.length > 0) {
                    this.downloadRemainingIcons(undownloadedIcons)
                } else await this.$store.dispatch('document/publish')
            } catch (error) {
                await this.$store.dispatch('document/setIsPublishing', false)
                this.print_clicked = false
                this.$bvModal.hide('printable-modal')
                throw error
            }
        },
        downloadRemainingIcons(icons) {
            let requests = []
            icons.forEach((file) => {
                requests.push(
                    ImageUploadApi.downloadIcon({
                        raster: file?.iconObject.best_raster_size,
                        filename: file?.name,
                        docId: this.document.id,
                    }),
                )
            })

            Promise.all(requests).finally(() => {
                this.$store.dispatch(this.persistAction).then(() => {
                    this.$store.dispatch('document/publish')
                })
            })
        },
    },
})
</script>

<style scoped>
.close-icon {
    top: 5px;
    right: 5px;
    height: 24px;
    width: 24px;
    z-index: 2;
}
</style>
