<template>
    <div class="card-body pb-1 pt-0">
        <div class="d-flex align-items-center">
            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted">Divider Width</label>
                <b-form-input
                    v-model="itemData.line_width"
                    type="range"
                    min="25"
                    max="100"
                    @input="updateData"
                ></b-form-input>
            </div>

            <div class="mx-3">
                <label class="font-weight-bold mb-0 f-11 text-muted">Line Thickness</label>
                <b-form-input
                    v-model="itemData.line_weight"
                    type="range"
                    min="1"
                    max="10"
                    @input="updateData"
                ></b-form-input>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted">Color</label>
                <input v-model="itemData.line_color" type="color" @input="updateData" />
            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="flex-grow-1 mr-3">
                <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold">Top Padding</div>
                    <div class="text-secondary">{{ itemData.top_padding }}</div>
                </label>
                <b-form-input
                    v-model="itemData.top_padding"
                    type="range"
                    :min="0"
                    :max="2"
                    :step="0.1"
                    @input="updateData"
                ></b-form-input>
            </div>

            <div class="flex-grow-1">
                <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                    <div class="font-weight-bold">Bottom Padding</div>
                    <div class="text-secondary">{{ itemData.bottom_padding }}</div>
                </label>
                <b-form-input
                    v-model="itemData.bottom_padding"
                    type="range"
                    :min="0"
                    :max="3"
                    :step="0.1"
                    @input="updateData"
                ></b-form-input>
            </div>
        </div>

        <div>
            <label class="font-weight-bold mb-0 f-11 text-muted">Line Style</label>
            <div class="d-flex row">
                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="groove"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="groove"></div>
                        Groove
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="dashed"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="dashed"></div>
                        Dashed
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="solid"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="solid"></div>
                        Solid
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="outset"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="outset"></div>
                        Outset
                    </div>
                </div>
            </div>
            <div class="d-flex row mt-2">
                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="dotted"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="dotted"></div>
                        Dotted
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="double"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="double"></div>
                        Double
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="ridge"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="ridge"></div>
                        Ridge
                    </div>
                </div>

                <div class="d-flex align-items-center col-md-3">
                    <b-form-radio
                        v-model="itemData.line_style"
                        name="line-style"
                        value="inset"
                        @change="updateData"
                    ></b-form-radio>
                    <div class="f-11 text-muted">
                        <div class="inset"></div>
                        Inset
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Divider',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            itemData: {
                ...this.data,
            },
        }
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
    },
})
</script>
