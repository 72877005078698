<template>
    <div>
        <b-form-group class="mb-0">
            <b-form-input
                :id="'worksheet-input-' + index"
                type="text"
                placeholder="Letters or words to copy (optional)"
                :value="item.term"
                @input="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { term: $event },
                    })
                "
            />
        </b-form-group>

        <b-form-group class="mb-0 pt-2">
            <b-form-select
                :value="item.trace_style"
                :options="['Trace', 'Solid', 'Stroked']"
                @change="
                    $store.dispatch('document/updateItem', {
                        index: index,
                        item: { trace_style: $event },
                    })
                "
            />
        </b-form-group>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

export default defineComponent({
    name: 'HandwritingItemForm',
    props: {
        index: {
            type: Number,
            required: true,
        },
    },
    computed: {
        ...mapState(['worksheet']),
        ...mapGetters({
            worksheetItems: 'document/worksheetItems',
            style: 'document/worksheetStyle',
        }),
        item() {
            return this.worksheetItems[this.index]
        },
    },
})
</script>
